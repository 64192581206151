import React, { useState, useEffect, useRef } from "react";
import SidebarMenu from "./SidebarMenu";
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DashboardHeader from "./DashboardHeader";

const Swap = (props) => {
    document.title = `Users - ${process.env.REACT_APP_APP_NAME}`;
    const [stakedata, setstakedata] = useState([]);
    const [isActive, setisActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const [bonusAddress, setbonusAddress] = useState(null);
    const [bonusPer, setbonusPer] = useState(0);
    const [bonusLD, setbonusLD] = useState(0);
    const [bonusError, setbonusError] = useState("");
    const [bonusRefadd, setbonusRefadd] = useState("");
    const [bonusRefper, setbonusRefper] = useState(0);
    const [bonusRefdollar, setbonusRefdollar] = useState(0);

    const bonusModal = useRef();
    const bonusToggle = useRef();

    const history = useNavigate();

    // var loginstatus = props.checklogin();
    // if (!loginstatus) {
    //     history('/dashboard')
    // }

    const toggleClass = () => {
        setisActive(!isActive);
    };
    const fetchUsers = async (page) => {
        setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap2", submethod: "get", page: page, per_page: perPage })
            .then((res) => {
                setstakedata(res.data.data);
                setTotalRows(res.data.total);
            });
        setLoading(false);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
        if (searchInput !== "") {
            getSearchData(page);
        } else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };

    const getSearchData = async (page) => {
        // console.log("getsearch data", searchInput);
        if (searchInput.trim() !== "") {
            setLoading(true);
            await axios
                .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                    method: "swap2",
                    submethod: "search",
                    input: searchInput,
                    page: page,
                    per_page: perPage,
                })
                .then((res) => {
                    setstakedata(res.data.data);
                    setLoading(false);
                    setTotalRows(res.data.total);
                });
        } else {
            fetchUsers(1);
        }
    };
    const bonusGive = async () => {
        // console.log("bonusModal.current.click();", bonusToggle.current.checked, bonusPer, bonusLD, bonusAddress);

        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                method: "bonusIncome",
                status: bonusToggle.current.checked,
                bonus_per: bonusPer,
                locked_dollar: bonusLD,
                address: bonusAddress,
            })
            .then((res) => {
                if (res.data.error) {
                    setbonusError(res.data.error);
                    return;
                }
                fetchUsers(currentPage);
                setbonusError("");
                bonusModal.current.click();
            });
    };
    const setBonusData = (address, ld, per, status, reff_address, ref_per, ref_dollar) => {
        // console.log(address, ld, per, status);
        setbonusError("");
        setbonusAddress(address);
        setbonusLD(ld);
        setbonusPer(per);
        setbonusRefadd(reff_address);
        setbonusRefper(ref_per);
        setbonusRefdollar(ref_dollar);
        bonusToggle.current.checked = status;
    };
    // useEffect(() => {
    //     stake();
    // }, [props])

    const columns = [
        {
            name: "#",
            selector: (row) => row["id"],
            key: "sr",
            width: "100px",
        },
        {
            name: "Hash",
            selector: (row) => row["hash"],
            key: "hash",
            cell: (data) => (
                <>
                    <span onClick={(e) => props.copyaddress(data.hash)} id={data.hash}>
                        <i className="fa fa-copy" />
                    </span>
                    &nbsp;<span> {data.s_hash}</span>
                </>
            ),
            width: "430px",
        },
        {
            name: "Address",
            selector: (row) => row["address"],
            key: "address",
            cell: (data) => (
                <>
                    <span onClick={(e) => props.copyaddress(data.address)} id={data.address}>
                        <i className="fa fa-copy" />
                    </span>
                    &nbsp;<span> {data.s_address}</span>
                </>
            ),
            width: "430px",
        },
        {
            name: "Amount",
            selector: (row) => row["amount"],
            key: "amount",
            width: "110px",
        },
        {
            name: "Datetime",
            selector: (row) => row["datetime"],
            key: "datetime",
            width: "190px",
        },
        {
            name: "Status",
            selector: (row) => (row["status"] === 1 || row["status"] === "1" ? "Swap" : "Whitelist"),
            key: "status",
            width: "190px",
        },
    ];
    const data = [...stakedata];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search",
    };

    useEffect(() => {
        // stake();

        if (searchInput !== "") {
            getSearchData(currentPage);
        } else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage]);
    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section
                className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}
            >
                <DashboardHeader toggleClass={toggleClass} />
                <div className="modal fade" id="bonusincome" tabindex="-1" aria-labelledby="bonusincomeLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="bonusincomeLabel">
                                    Give Bonus Reward
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="h5">{bonusAddress}</div>
                                <hr />
                                <div className="h6">Reff: {bonusRefadd}</div>
                                <div className="h6">Reff %: {bonusRefper} %</div>
                                <div className="h6">Reff $: ${bonusRefdollar}</div>
                                <hr />
                                <div className="d-flex">
                                    <label className="h6">Bonus Reward &nbsp;&emsp;</label>
                                    <div className="custom-control-lg  custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheck1"
                                            defaultChecked={true}
                                            ref={bonusToggle}
                                        />
                                        <label className="custom-control-label" for="customCheck1"></label>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Bonus (%) </span>
                                    </div>
                                    <input type="text" className="form-control" value={bonusPer} onChange={(e) => setbonusPer(e.target.value)} />
                                </div>
                                <br />
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Dollar Locked($) </span>
                                    </div>
                                    <input type="text" className="form-control" value={bonusLD} onChange={(e) => setbonusLD(e.target.value)} />
                                </div>
                                <div className="p-1">
                                    {bonusError ? (
                                        <div className="alert alert-danger" role="alert">
                                            {bonusError}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={bonusModal}>
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary" onClick={() => bonusGive()}>
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_content_iner overly_inner ">
                    <div className="container-fluid p-0 ">
                        <div className="row">
                            <div className="col-12"></div>
                            <div className="col-lg-12">
                                <div className="white_card mb_30 card_height_100">
                                    <div className="white_card_header pb-0">
                                        <div className="box_header m-0">
                                            <div className="main-title">
                                                <h3 className="m-0">Swap</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white_card_body pt-0" style={{ marginTop: "50px" }}>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)}>
                                            Search
                                        </button>
                                        <div className="QA_section">
                                            <div className="QA_table mb-0 transaction-table">
                                                <div className="table-responsive">
                                                    <DataTableExtensions {...tableData} print={false} export={false}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            // progressPending={loading}
                                                            defaultSortField="id"
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            paginationTotalRows={totalRows}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            pagination
                                                            highlightOnHover
                                                            paginationServer
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </section>
        </>
    );
};

export default Swap;
