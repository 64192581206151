import React, { useEffect, useRef } from "react";
import DecoupledcEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import CKEditor from "@ckeditor/ckeditor5-react";

function MaintainTextEditor({ onChange, editorLoaded, name, value }) {

  return (
    <div>

      {editorLoaded === true ? (
        <CKEditor
          editor={DecoupledcEditor}
          onInit={(editor) => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              );
          }}

          data={value ? value : ''}
          name={name ? name : ''}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data)
          }}
        />
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}

export default MaintainTextEditor;