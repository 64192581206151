import React, { useState, useEffect } from "react";
import SidebarMenu from "./SidebarMenu";
import axios from 'axios';
import './styles.css';
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import Footer from "./Footer";
import DashboardHeader from './DashboardHeader';

const ListAllStaking = (props) => {
    document.title = `All Staking - ${process.env.REACT_APP_APP_NAME}`
    // console.log(props);
    const [stakedata, setstakedata] = useState([]);
    const [isActive, setisActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);


    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/dashboard')
    }
    // const copyaddress = (address) =>{
    //     copy(address);
    // }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    const fetchUsers = async page => {

        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allstake", page: page, per_page: perPage })
            .then(res => {
                setstakedata(res.data.data);
                setTotalRows(res.data.total);

            })
        setLoading(false);
    };
    const handlePageChange = page => {

        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchStakeDeposit", search: searchInput, page: page, per_page: perPage })
                .then(res => {
                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }
    // const stake = async () => {
    //     setLoading(true)
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allstake" })
    //         .then(res => {
    //             // console.log("dashallstake", res.data);
    //             setstakedata(res.data);
    //             setLoading(false)
    //             // console.log("sas", stakedata);
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])

    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    const columns = [

        {
            name: "#",
            selector: row => row['sr'],
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: row => row["status"],
            key: "status",
            width: "100px"
        },
        {
            name: "Hash#",
            selector: row => row["hash"],
            cell: row => <a target="_blank" href={`${process.env.REACT_APP_TXN_URL}${row['fhash']}`}>{row['hash']}</a>,
            key: "hash",
            width: "150px"
        },
        {
            name: "Member",
            selector: row => row["ffromaddress"],
            cell: row => <><span onClick={(e) => props.copyaddress(row['ffromaddress'])} id={row['ffromaddress']}><i className='fa fa-copy' />&nbsp;</span><span> {row['ffromaddress']}</span></>,
            key: "ffromaddress",
            width: "430px"
        },
        {
            name: "Invest($)",
            selector: row => row["total_investment"],
            key: "total_investment",
            width: "120px"
        },

        {
            name: "Token Rate ",
            selector: row => row["token_rate"],
            key: "token_rate",
            width: "120px"
        },
        {
            name: "Tokens",
            selector: row => row["total_token"],
            width: "120px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "200px"
        },
        {
            name: "End Datetime",
            selector: row => row["end_datetime"],
            key: "end_datetime",
            width: "200px"
        },
        {
            name: "Type",
            selector: row => row["t_type"],
            key: "t_type",
            width: "100px"
        }
    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };


    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div className="main_content_iner overly_inner ">
                    <div className="container-fluid p-0 ">

                        <div className="row">
                            <div className="col-12">
                            </div>

                            <div className="col-lg-12">
                                <div className="white_card mb_30 card_height_100">
                                    <div className="white_card_header pb-0">
                                        <div className="box_header m-0">
                                            <div className="main-title">
                                                <h3 className="m-0">List All Staking</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white_card_body pt-0" style={{ marginTop: "50px" }}>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                        <div className="QA_section">
                                            <div className="QA_table mb-0 transaction-table">

                                                <div className="table-responsive">
                                                    <DataTableExtensions {...tableData}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            progressPending={loading}
                                                            defaultSortField="id"
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            paginationTotalRows={totalRows}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            pagination
                                                            highlightOnHover
                                                            paginationServer
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </section>
        </>
    )
}

export default ListAllStaking;