import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import axios from 'axios';
import DashboardHeader from './DashboardHeader';
import { useNavigate } from "react-router-dom";
import SidebarMenu from './SidebarMenu';
var dateTime = require('node-datetime');
const AddWithdraw = (props) => {
    // console.log('yser',props.address);
    const [hash, sethash] = useState(null);
    const [address, setaddress] = useState(null);
    const [amt, setamt] = useState(0);
    const [tknrate, settknrate] = useState(0);
    const [tokens, settokens] = useState(0);
    const [datetime, setdatetime] = useState(null);
    const [end_datetime, setend_datetime] = useState(null);
    const [type, settype] = useState(0);
    const [error, seterrpr] = useState(null);
    const [isActive, setisActive] = useState(true);
    const [withAmt, setwithAmt] = useState(0);
    const [withRcv, setwithRcv] = useState(0);
    // const [withRcvD, setwithRcvD] = useState(0);
    const [feeD, setfeeD] = useState(0);
    const [feeT, setfeeT] = useState(0);
    const [burnT, setburnT] = useState(0);
    const [burnD, setburnD] = useState(0);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }

    const toggleClass = () => {
        setisActive(!isActive)
    };

    const onenteramt = (e) => {
        // console.log("Token rate", ppliverate);
        var converttoliverate = (((process.env.REACT_APP_WITHDRAW_FEE * amt) / 100) / tknrate)
        setwithRcv(amt / tknrate - converttoliverate);
        // setwithRcvD(withAmt * ppliverate - ((process.env.REACT_APP_WITHDRAW_FEE * withAmt) / 100))
        setfeeD((process.env.REACT_APP_WITHDRAW_FEE * amt) / 100)
        setfeeT(((process.env.REACT_APP_WITHDRAW_FEE * amt) / 100) / tknrate)
        
        setburnT(((process.env.REACT_APP_BURN_PER * amt) / 100) / tknrate)
        setburnD((process.env.REACT_APP_BURN_PER * amt) / 100)
        // console.log("withAmtburn", burnD);
    }
    const getUserData = async (account) => {
        if (!account) {
            return
        }
        await axios.post(process.env.REACT_APP_BACKEND_LINK, { method: "getuser", address: account.toLowerCase() })
            .then(res => {
                console.log("resd", res.data);
                if (!res.data.length) {
                    seterrpr("Address not found")
                    return
                }
                seterrpr("")
                if (res.data[0].status === 1) {
                    if(res.data[0].restaking){
                        settype(1)
                    }else{
                        seterrpr("Restake not activate yet")
                    }

                } else {
                    settype(0)
                }
            })
    };

    const addtr = async () => {
        console.log("type" + type);
        const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        if (!addressRegex.test(address)) {
            seterrpr("Enter valid Address")
            return
        }
        if (!amt) {
            seterrpr("Enter valid Amount")
            return
        }
        if (!tknrate) {
            seterrpr("Enter valid Token Rate")
            return
        }
        var dt = dateTime.create();
                    var date_formatted = dt.format('Y-m-d H:M:S');
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            method: "withdraw",
            address: address.toLowerCase(), total: amt, rate: tknrate, fee_dollar: feeD, fee_token: feeT, burn_per: process.env.REACT_APP_BURN_PER, total_burn_dollar: burnD, total_burn_token: burnT, total_receive: withRcv, datetime: date_formatted, bnb_hash:0,isAdmin:true
        })
            .then(res => {
                if (res.data.error) {
                    seterrpr(res.data.error)
                    return
                }
                seterrpr(res.data.data)
                console.log("fsdf", res);
            })
    }
    useEffect(() => {
        settokens(amt / tknrate)
    }, [amt, tknrate])
    // useEffect(() => {
    //     getUserData(address)
    // }, [address])
    return (
        <>

            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">
                        <div className="col-xl-12">
                            <div className="white_card card_height_100 mb_30">
                                <div className="white_card_header">
                                    <div className="box_header m-0">
                                        <div className="main-title">
                                        </div>
                                    </div>
                                </div>
                                <div className="white_card_body">
                                    <div className="QA_section">
                                        <div className="white_box_tittle list_header">
                                            <h4>Add Withdraw</h4>
                                            <div className="box_right d-flex lms_block">
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div class="col-lg-6 form-group">
                                                <h5>Address</h5>
                                                <input type="text" class="form-control" placeholder="Address" autocomplete="on" onChange={(e) => setaddress(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Amount $</h5>
                                                <input type="number" class="form-control" placeholder="Enter $" autocomplete="on" onChange={(e) => setamt(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Token Rate $</h5>
                                                <input type="number" class="form-control" placeholder="Token Rate" autocomplete="on" onKeyUp={(e) => onenteramt()} onChange={(e) => settknrate(e.target.value)} />
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <h5>Buy Oxyo2 Fee</h5>
                                                <span value="100 USD" type="text" style={{ fontSize: "20px" }}>$ {feeD} ({process.env.REACT_APP_WITHDRAW_FEE} %)</span><br/> 
                                                <h5>Burn Fee $</h5>
                                                <span value="100 USD" type="text" style={{ fontSize: "20px" }}>$ {burnD} ({process.env.REACT_APP_BURN_PER} %)</span><br/> 
                                                <h5>Burn Fee Token</h5>
                                                <span value="100 USD" type="text" style={{ fontSize: "20px" }}>{burnT} ({tknrate})</span>
                                                <h5>Total Receive</h5>
                                                <span value="100 USD" type="text" style={{ fontSize: "20px" }}>{withRcv}</span>
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <div className='pt-2'>
                                                    <span className='text-danger'>{error}</span>
                                                    <br />
                                                    <button type='button' class="btn_6 full_width text-center" onClick={() => addtr()} >Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </section>
        </>
    );
};

export default AddWithdraw;