import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
const UserBuySell = (props) => {
    // console.log('yser',props.address);
    const [stakedata, setstakedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const fetchUsers = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap", submethod: "swapaddress",address:props.address, page: page, per_page: perPage })
            .then(res => {
                // console.log("ress",res.data);
                setstakedata(res.data.data);
                setTotalRows(res.data.dataLength);
            })
        setLoading(false);
    };
    const handlePageChange = page => {
        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
       
        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        // console.log("perpage page : ", perPage , page)
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "UserReferralReward", search: searchInput, page: page, per_page: perPage })
            .then(res => {
                // console.log("res is : ", res.data);
                setstakedata(res.data.data)
                setLoading(false)
                setTotalRows(res.data.total);
            })
    }
    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else{
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    // const stake = async () => {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", fromaddress: props.address, submethod: "level" })
    //         .then(res => {
    //             setstakedata(res.data);
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])

    const columns = [
        {
            name: "#",
            selector: row => row["id"],
            key: "id",
            width: "50px"
        },
        {
            name: "Status",
            selector: row => row.status ? "🟢" : "🔴",
            key: "status",
            width: "100px"
        },
        {
            name: "Hash",
            selector: row => row["hash"],
            cell: (data) => <><a target="_blank" href={`${process.env.REACT_APP_TXN_URL}${data.hash}`}>{data.hash.substr(0, 5) + "..." + data.hash.substr(-5, 5)}</a></>,
            key: "hash",
            width: "150px"
        },
        // {
        //     name: "Address",
        //     selector: row => row["address"],
        //     cell: (data) => <><span onClick={(e) => props.copyaddress(data.address)} ><i className='fa fa-copy' /></span>&nbsp;<span> {data.address}</span></>,
        //     key: "address",
        //     width: "220px"
        // },
        {
            name: "Amount",
            selector: row => row["amount"],
            cell: (data) => <>{data.amount_oxy.toFixed(2)} {process.env.REACT_APP_TICKER}</>,
            key: "amount",
            width: "120px"
        },
        {
            name: "Amount",
            selector: row => row["amount"],
            cell: (data) => <>{data.amount_busd.toFixed(2)} {process.env.REACT_APP_BUSDTICKER}</>,
            key: "amount",
            width: "150px"
        },
        {
            name: "Rate($)",
            selector: row => row["rate"],
            cell: (data) => <>$ {data.rate.toFixed(2)}</>,
            key: "rate",
            width: "120px"
        },
        {
            name: "Type",
            selector: row => row["type"],
            cell: (data) => <><span className={`${data.type === 0 ? 'text-success' : 'text-danger'}`}>{data.type === 0 ? 'Buy' : 'Sell'}</span></>,
            key: "type",
            width: "120px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "200px"
        }
    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    return (
        <>
            <div className="col-xl-12">
                <div className="white_card card_height_100 mb_30">
                    <div className="white_card_header">
                        <div className="box_header m-0">
                            <div className="main-title">
                            </div>
                        </div>
                    </div>
                    <div className="white_card_body">
                        <div className="QA_section">
                            <div className="white_box_tittle list_header">
                                <h4> List of all Buy/Sell</h4>
                                <div className="box_right d-flex lms_block">
                                </div>
                            </div>
                            <div className="QA_table mb_30">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                         columns={columns}
                                         data={data}
                                         noHeader
                                         defaultSortField="id"
                                         progressPending={loading}
                                         // sortIcon={<SortIcon />}
                                         defaultSortAsc={true}
                                         pagination
                                         paginationServer
                                         paginationTotalRows={totalRows}
                                         onChangeRowsPerPage={handlePerRowsChange}
                                         onChangePage={handlePageChange}
                                         highlightOnHover
                                         dense
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserBuySell;