import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import ListAllStaking from "./Components/ListAllStaking";
import ListAllLeaders from "./Components/ListAllLeaders";
import ListAllWithdrawal from "./Components/ListAllWithdrawal";
import UserAddress from "./Components/UserAddress";
import TableData from "./Components/TableData";
import Register from "./Components/Register";
import ForgotPass from "./Components/ForgotPass";
import ListUsers from "./Components/ListUsers";
import Liverate from "./Components/popup/LiveRate";
import Userdashboard from "./Components/popup/UserDashboard";
import Website from "./Components/popup/Website";
import axios from "axios";
import Levelincome from "./Components/Report/LevelIncome";
import Dailyreport from "./Components/Report/Dailyreport";
import Leadershipincome from "./Components/Report/LeadershipIncome";
import Stakingincome from "./Components/Report/StakingIncome";
import Leadership from "./Components/leadership/Leadership";
import SrExecutive from "./Components/leadership/SrExecutive";
import CoOrdinator from "./Components/leadership/CoOrdinator";
import TeamLeader from "./Components/leadership/TeamLeader";
import SrTeamLeader from "./Components/leadership/SrTeamLeader";
import Director from "./Components/leadership/Director";
import CountryHead from "./Components/leadership/CountryHead";
import CountryCeo from "./Components/leadership/CountryCeo";
import President from "./Components/leadership/President";
import Maintenance from "./Components/Maintenance/Maintenance";
import Crone from "./Components/Crone/Crone";
import ChangePassword from "./Components/ChangePassword";
import FAQs from "./Components/FAQs/FAQs";
import AllFAQ from "./Components/FAQs/AllFAQ";
import UpdateFAQ from "./Components/FAQs/UpdateFAQ";
import Newsletter from "./Components/Newsletter";
import SocialMedia from "./Components/SocialMedia/SocialMedia";
import copy from "copy-to-clipboard";
import ListBuySale from "./Components/ListBuySale";
import ListUsersPRestake from "./Components/ListUsersPRestake";
import ListUsersRestaked from "./Components/ListUsersRestaked";
import FlushedIncome from "./Components/Report/FlushedIncome";
import PendingWithdrawal from "./Components/Report/PendingWithdrawal";
import BonusReward from "./Components/Report/BonusReward";
import Swap from "./Components/Swap";
import SrDirector from './Components/leadership/SrDirector';
import AddTransaction from './Components/AddTransaction';
import AddWithdraw from './Components/AddWithdraw';
import AddDailyReward from './Components/AddDailyReward';

function App() {
    const abi = [
        { inputs: [], stateMutability: "nonpayable", type: "constructor" },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "address", name: "owner", type: "address" },
                { indexed: true, internalType: "address", name: "spender", type: "address" },
                { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
            ],
            name: "Approval",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
                { indexed: true, internalType: "address", name: "newOwner", type: "address" },
            ],
            name: "OwnershipTransferred",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: "address", name: "from", type: "address" },
                { indexed: true, internalType: "address", name: "to", type: "address" },
                { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
            ],
            name: "Transfer",
            type: "event",
        },
        {
            inputs: [
                { internalType: "address", name: "owner", type: "address" },
                { internalType: "address", name: "spender", type: "address" },
            ],
            name: "allowance",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "spender", type: "address" },
                { internalType: "uint256", name: "amount", type: "uint256" },
            ],
            name: "approve",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "account", type: "address" }],
            name: "balanceOf",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_address", type: "address" }],
            name: "blockUser",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
            name: "burn",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "account", type: "address" },
                { internalType: "uint256", name: "amount", type: "uint256" },
            ],
            name: "burnFrom",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_address", type: "address" }],
            name: "checkBlocked",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
        },
        { inputs: [], name: "decimals", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" },
        {
            inputs: [
                { internalType: "address", name: "spender", type: "address" },
                { internalType: "uint256", name: "subtractedValue", type: "uint256" },
            ],
            name: "decreaseAllowance",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "spender", type: "address" },
                { internalType: "uint256", name: "addedValue", type: "uint256" },
            ],
            name: "increaseAllowance",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "", type: "address" }],
            name: "isBlocked",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
        },
        { inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" },
        { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" },
        {
            inputs: [
                { internalType: "address", name: "_address", type: "address" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "uint256", name: "_endstake", type: "uint256" },
            ],
            name: "removeStaker",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
        {
            inputs: [
                { internalType: "address", name: "_address", type: "address" },
                { internalType: "uint256", name: "tamount", type: "uint256" },
                { internalType: "uint256", name: "_endstake", type: "uint256" },
            ],
            name: "stake",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            name: "stakers",
            outputs: [
                { internalType: "address", name: "_address", type: "address" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "bool", name: "_isStaker", type: "bool" },
                { internalType: "uint256", name: "_value", type: "uint256" },
                { internalType: "uint256", name: "_balance", type: "uint256" },
                { internalType: "bool", name: "_isRestake", type: "bool" },
                { internalType: "uint256", name: "_stakeend", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
        },
        { inputs: [], name: "symbol", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" },
        {
            inputs: [],
            name: "totalStaking",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "totalSupply",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "recipient", type: "address" },
                { internalType: "uint256", name: "amount", type: "uint256" },
            ],
            name: "transfer",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "sender", type: "address" },
                { internalType: "address", name: "recipient", type: "address" },
                { internalType: "uint256", name: "amount", type: "uint256" },
            ],
            name: "transferFrom",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
            name: "transferOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_address", type: "address" }],
            name: "unblockUser",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
    ];
    const with_abi = [
        {
            inputs: [
                { internalType: "address", name: "_address", type: "address" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "uint256", name: "_endstake", type: "uint256" },
                { internalType: "uint256", name: "_password", type: "uint256" },
            ],
            name: "clmstk",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        { inputs: [], name: "dpst", outputs: [], stateMutability: "payable", type: "function" },
        {
            inputs: [{ internalType: "address", name: "_counter", type: "address" }],
            name: "setCounterAddr",
            outputs: [],
            stateMutability: "payable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "_address", type: "address" },
                { internalType: "uint256", name: "_amount", type: "uint256" },
                { internalType: "uint256", name: "_burnfee", type: "uint256" },
                { internalType: "uint256", name: "_password", type: "uint256" },
            ],
            name: "wdrtkn",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "value", type: "uint256" }],
            name: "wrdrcon",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "baseToken",
            outputs: [{ internalType: "contract IBEP20", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "_address", type: "address" }],
            name: "checkTokenBalance",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "coinBalance",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "", type: "address" }],
            name: "isBlocked",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
        },
        { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" },
        {
            inputs: [],
            name: "password",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "password1",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            name: "stakers",
            outputs: [
                { internalType: "address", name: "_address", type: "address" },
                { internalType: "uint256", name: "_timestamp", type: "uint256" },
                { internalType: "bool", name: "_isStaker", type: "bool" },
                { internalType: "uint256", name: "_value", type: "uint256" },
                { internalType: "uint256", name: "_balance", type: "uint256" },
                { internalType: "bool", name: "_isRestake", type: "bool" },
                { internalType: "uint256", name: "_stakeend", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
        },
        { inputs: [], name: "total", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" },
    ];
    const [Setting, setSetting] = useState([]);
    function checklogin() {
        if (window.sessionStorage.getItem("DZPAZrclEQ3fDHSc") === "ONqUlF") {
            return true;
        } else {
            return false;
        }
    }
    const onGetSetting = async (e) => {
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getsetting", setting: 1 }).then((res) => {
            setSetting(res.data[0]);
        });
    };
    const copyaddress = (address) => {
        copy(address);
    };
    checklogin();
    useEffect(() => {
        onGetSetting();
    }, []);
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Login />}></Route>
                    <Route
                        exact
                        path="/dashboard"
                        element={<Dashboard abi={abi} with_abi={with_abi} Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/listallstaking"
                        element={<ListAllStaking Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/listallleaders"
                        element={<ListAllLeaders Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/listallwithdrawal"
                        element={<ListAllWithdrawal Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/listalluser"
                        element={<ListUsers Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/datatable"
                        element={<TableData Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/userreport"
                        element={<UserAddress Setting={Setting} copyaddress={copyaddress} report="report" checklogin={checklogin} />}
                    ></Route>
                    <Route exact path="/register" element={<Register Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route
                        exact
                        path="/forgotpass"
                        element={<ForgotPass Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route exact path="/website" element={<Website Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route
                        exact
                        path="/userdashboard"
                        element={<Userdashboard Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route exact path="/liverate" element={<Liverate Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route
                        exact
                        path="/levelincome"
                        element={<Levelincome Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/stakingincome"
                        element={<Stakingincome Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/leadershipincome"
                        element={<Leadershipincome Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/dailyreport"
                        element={<Dailyreport Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/maintenance"
                        element={<Maintenance Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} onGetSetting={onGetSetting} />}
                    ></Route>
                    <Route exact path="/crone" element={<Crone />}></Route>
                    <Route
                        exact
                        path="/coordinator"
                        element={<CoOrdinator Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/countryceo"
                        element={<CountryCeo Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/countryhead"
                        element={<CountryHead Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route exact path="/director" element={<Director Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route
                        exact
                        path="/leaderrole"
                        element={<Leadership Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/president"
                        element={<President Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/srexecutive"
                        element={<SrExecutive Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/srteamleader"
                        element={<SrTeamLeader Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/teamleader"
                        element={<TeamLeader Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                     <Route exact path="/srdirector" element={<SrDirector Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route
                        exact
                        path="/ChangePassword"
                        element={<ChangePassword Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>

                    <Route exact path="/FAQs" element={<FAQs Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route exact path="/AllFAQ" element={<AllFAQ Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route
                        exact
                        path="/UpdateFAQ"
                        element={<UpdateFAQ Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>

                    <Route
                        exact
                        path="/newsletter"
                        element={<Newsletter Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/socialMedia"
                        element={<SocialMedia Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>

                    <Route
                        exact
                        path="/listbuysale"
                        element={<ListBuySale Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>

                    <Route
                        exact
                        path="/listpendingrestake"
                        element={<ListUsersPRestake Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/restakeduser"
                        element={<ListUsersRestaked Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>

                    <Route
                        exact
                        path="/flushedIncome"
                        element={<FlushedIncome Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/pendingwithdrawal"
                        element={<PendingWithdrawal Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route
                        exact
                        path="/bonusrewards"
                        element={<BonusReward Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}
                    ></Route>
                    <Route exact path="/swap" element={<Swap Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route exact path="/AddTransaction" element={<AddTransaction Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route exact path="/AddWithdraw" element={<AddWithdraw Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                    <Route exact path="/AddDailyReward" element={<AddDailyReward Setting={Setting} copyaddress={copyaddress} checklogin={checklogin} />}></Route>
                </Routes>

            </BrowserRouter>
        </>
    );
}

export default App;
