import React, { useState, useEffect } from 'react';
import SidebarMenu from '../SidebarMenu';
import "../styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import DashboardHeader from '../DashboardHeader';
import Footer from '../Footer';
import FileSaver from "file-saver";
import XLSX from "xlsx";

const PendingWithdrawal = (props) => {
    document.title = `Pending Withdrawal - ${process.env.REACT_APP_APP_NAME}`
    const [stakedata, setstakedata] = useState([]);
    const [isActive, setisActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [rejectError, setrejectError] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const fetchUsers = async page => {

        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allpendingwithdraw", page: page, per_page: perPage })
            .then(res => {
                setstakedata(res.data.data);
                setTotalRows(res.data.total);

            })
        setLoading(false);
    };
    const handlePageChange = page => {

        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchAllPendingWithdraw", search: searchInput, page: page, per_page: perPage })
                .then(res => {
                    console.log("YES",res.data.data)
                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }
    const approvewithdrawal = async (id) => {
        console.log("FData",id)
        
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "prioritygivewithdraw", address : id })
            .then(res => {
                if (res.data.error) {
                    setrejectError(res.data.error)
                    return
                }
                setrejectError('')
                fetchUsers(currentPage);
            })
    }
    const rejectwithdrawal = async (address) => {
        // console.log("bonusModal.current.click();", bonusToggle.current.checked, bonusPer, bonusLD, bonusAddress);

        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "rejectWithdrawal", address: address })
            .then(res => {
                if (res.data.error) {
                    setrejectError(res.data.error)
                    return
                }
                setrejectError('')
                fetchUsers(currentPage);
            })
    }
    // ******** XLSX with object key as header *************
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allpendingwithdraw", page: 1, per_page: 10000 })
            .then(res => {
                var newData = []
                for (let i = 0; i < res.data.data.length; i++) {
                    const e = res.data.data[i];
                    var datas = {
                        "sr": e.sr,
                        "Address": e.faddress,
                        "Total": e.total,
                        "Rate": e.rate,
                        "Fee ($)": e.fee_dollar,
                        "Fee (Token)": e.fee_token,
                        "Burn Per(%)": e.burn_per,
                        "Total Burned ($)": e.total_burn_dollar,
                        "Total Burn (Token)": e.total_burn_token,
                        "Total Recieve": e.total_receive,
                        "Date Time": e.datetime
                    };
                    newData.push(datas)
                }
                const ws = XLSX.utils.json_to_sheet(newData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, "pendingwithdrawal" + fileExtension);
            })

    };

    // const stake = async () => {
    //     setLoading(true)
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allwithdraw" })
    //         .then(res => {
    //             setstakedata(res.data);
    //             setLoading(false)
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])
    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    const columns = [
        {
            name: "#",
            selector: row => row["sr"],
            key: "sr",
            width: "100px"
        },
        {
            name: "",
            selector: row => row["reject"],
            cell: (data) => <>{ <><button className='btn btn-primary btn-sm' onClick={() => approvewithdrawal(data.faddress)} >Approve</button>&emsp;<button className='btn btn-primary btn-sm' onClick={() => rejectwithdrawal(data.faddress)} >Reject</button></> }</>,
            // cell: (data) => <>{data.ref_user_bonus ? <button className='btn btn-primary btn-sm' onClick={() => setBonusData(data.faddress, data.dollar_locked, data.bonus_assigned_per, data.bonus_income, data.freff_address, data.ref_user_bonus_per, data.ref_user_bonus_dollar)} data-toggle="modal" data-target="#bonusincome" >Bonus</button> : ''}</>,
            key: "_id",
            width: "200px"
        },
        {
            name: "Address",
            selector: row => row["address"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.faddress)} ><i className='fa fa-copy' /></span>&nbsp;<span> {data.address}</span></>,
            key: "address",
            width: "220px"
        },
        {
            name: "Total",
            selector: row => row["total"],
            key: "total",
            width: "120px"
        },

        {
            name: "Rate",
            selector: row => row["rate"],
            key: "rate",
            width: "120px"
        },
        {
            name: "Fee($)",
            selector: row => row["fee_dollar"],
            key: "fee_dollar",
            width: "120px"
        },

        {
            name: "Fee(token)",
            selector: row => row["fee_token"],
            key: "fee_token",
            width: "120px"
        },

        {
            name: "Burn(%)",
            selector: row => row["burn_per"],
            key: "burn_per",
            width: "120px"
        },
        {
            name: "Burn($)",
            selector: row => row["total_burn_dollar"],
            key: "total_burn_dollar",
            width: "120px"
        },
        {
            name: "Burn(Token)",
            selector: row => row["total_burn_token"],
            key: "total_burn_token",
            width: "120px"
        },
        {
            name: "Recieved",
            selector: row => row["total_receive"],
            key: "total_receive",
            width: "120px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "200px"
        }

    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    const toggleClass = () => {
        setisActive(!isActive)
    };



    return (
        <>

            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="white_card card_height_100 mb_30">
                                    <div class="white_card_header">
                                        <div class="white_box_tittle list_header mb-0">
                                            <h4>All Pending Withdrawals</h4>
                                            {rejectError ?
                                        <div className="alert alert-danger" role="alert">
                                            {rejectError}
                                        </div>
                                        : ""}
                                            <div class="box_right d-flex lms_block">
                                            </div>
                                            <button className='btn btn-primary' onClick={(e) => exportToCSV()}>Export to xls</button>
                                        </div>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                    </div>
                                    <div class="white_card_body">
                                        <div class="QA_section">
                                            <div class="QA_table mb_30">

                                                <DataTableExtensions {...tableData}>
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        noHeader
                                                        progressPending={loading}
                                                        defaultSortField="id"
                                                        // sortIcon={<SortIcon />}
                                                        defaultSortAsc={true}
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        pagination
                                                        highlightOnHover
                                                        paginationServer
                                                        dense
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </section>



        </>
    )
}


export default PendingWithdrawal;