import React from 'react';
import Footer from './Footer';

function ForgotPass() {
    return (
        <>
            <section style={{ backgroundColor: "#fff" }}>
                <div className="container-fluid">
                    <div className="white_box mb_30" style={{ height: "800px", width: "100%" }}>
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <h1 className="d-flex justify-content-center mt-5">Forgot Password</h1>
                                <div className="modal-content cs_modal" style={{ marginTop: "100px" }}>
                                    <div className="modal-header justify-content-center theme_bg_1">
                                        <h5 className="modal-title text_white">Log in</h5>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="form-group">
                                                <input type="text" className="form-control"
                                                    placeholder="Enter your email" />
                                            </div>
                                            <a href="#" className="btn_1 full_width text-center">SEND</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ForgotPass;