import React, { useState, useEffect,useRef } from 'react';
import SidebarMenu from './SidebarMenu';
import './styles.css';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import DashboardHeader from './DashboardHeader';

const ListUsers = (props) => {

    document.title = `Users - ${process.env.REACT_APP_APP_NAME}`
    const [stakedata, setstakedata] = useState([]);
    const [isActive, setisActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [bonusAddress, setbonusAddress] = useState(null);
    const [bonusPer, setbonusPer] = useState(0);
    const [bonusLD, setbonusLD] = useState(0);
    const [bonusError, setbonusError] = useState('');
    const [bonusRefadd, setbonusRefadd] = useState('');
    const [bonusRefper, setbonusRefper] = useState(0);
    const [bonusRefdollar, setbonusRefdollar] = useState(0);

    const bonusModal = useRef();
    const bonusToggle = useRef();

    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/dashboard')
    }

    const toggleClass = () => {
        setisActive(!isActive)
    };
    const fetchUsers = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allusers", page: page, per_page: perPage })
            .then(res => {
                setstakedata(res.data.data);
                setTotalRows(res.data.total);

            })
        setLoading(false);
    };
    const handlePageChange = page => {

        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };

    // const stake = async () => {
    //     setLoading(true)
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allusers" })
    //         .then(res => {
    //             // console.log("dashallstake", res.data);
    //             setstakedata(res.data);
    //             setLoading(false)
    //             // console.log("sas", stakedata);
    //         })
    // }

    const getSearchData = async (page) => {
        // console.log("getsearch data", searchInput);
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchUsers", search: searchInput, page: page, per_page: perPage })
                .then(res => {

                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }
    const bonusGive = async () => {
        // console.log("bonusModal.current.click();", bonusToggle.current.checked, bonusPer, bonusLD, bonusAddress);

        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "bonusIncome", status: bonusToggle.current.checked, bonus_per: bonusPer, locked_dollar: bonusLD, address: bonusAddress })
            .then(res => {
                if (res.data.error) {
                    setbonusError(res.data.error)
                    return
                }
                fetchUsers(currentPage);
                setbonusError('')
                bonusModal.current.click();
            })
    }
    const setBonusData = (address, ld, per, status, reff_address, ref_per, ref_dollar) => {
        // console.log(address, ld, per, status);
        setbonusError('')
        setbonusAddress(address);
        setbonusLD(ld);
        setbonusPer(per);
        setbonusRefadd(reff_address)
        setbonusRefper(ref_per)
        setbonusRefdollar(ref_dollar)
        bonusToggle.current.checked = status
    }
    // useEffect(() => {
    //     stake();
    // }, [props])

    const columns = [
        {
            name: "#",
            selector: row => row["sr"],
            key: "sr",
            width: "100px"
        },
        {
            name: "Super Reward",
            selector: row => row["status"],
            cell: (data) => <><span className={`${data.bonus_income ? 'text-success' : 'text-danger'}`}>{data.bonus_income ? `Activated ${data.bonus_assigned_per}%` : ""}</span> </>,
            key: "status",
            width: "140px"
        },
        {
            name: "Bonus Income",
            selector: row => row["restaking"],
            cell: (data) => <>{ <button className='btn btn-primary btn-sm' onClick={() => setBonusData(data.faddress, data.dollar_locked, data.bonus_assigned_per, data.bonus_income, data.freff_address, data.ref_user_bonus_per, data.ref_user_bonus_dollar)} data-toggle="modal" data-target="#bonusincome" >Bonus</button> }</>,
            // cell: (data) => <>{data.ref_user_bonus ? <button className='btn btn-primary btn-sm' onClick={() => setBonusData(data.faddress, data.dollar_locked, data.bonus_assigned_per, data.bonus_income, data.freff_address, data.ref_user_bonus_per, data.ref_user_bonus_dollar)} data-toggle="modal" data-target="#bonusincome" >Bonus</button> : ''}</>,
            key: "restaking",
            width: 100
        },
        {
            name: "Status",
            selector: row => row["status"],
            key: "status",
            width: "75px"
        },
        {
            name: "Member",
            selector: row => row["faddress"],
            key: "faddress",
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.faddress)} id={data.address}><i className='fa fa-copy' /></span>&nbsp;<span> {data.faddress}</span></>,
            width: "430px"
        },
        {
            name: "Referral Member",
            selector: row => row["freff_address"],
            cell: (data) => <>{data.freff_address ? <><span onClick={(e) => props.copyaddress(data.freff_address)} id={data.address}><i className='fa fa-copy' /></span>&nbsp;<span> {data.freff_address}</span></> :
                ''}</>,
            key: "reff_address",
            width: "430px"
        },
        {
            name: "Leadership",
            selector: row => row["position"],
            key: "position",
            width: "110px"
        },
        {
            name: "Wallet Balance",
            selector: row => row["wallet_balance"],
            key: "wallet_balance",
            width: "140px"
        },

        {
            name: "Total Staking",
            selector: row => row["total_staking"],
            key: "total_staking",
            width: "130px"
        },
        {
            name: "Total Staking",
            selector: row => row["total_staking_token"],
            cell: (row) => <span>{row["total_staking_token"]} {process.env.REACT_APP_TICKER}</span>,
            key: "total_staking_token",
            width: "170px"
        },

        {
            name: "Total Withdrawal",
            selector: row => row["total_withdrawal"],
            width: "150px"
        },
        {
            name: "Referrals",
            selector: row => row["totalrefferal"],
            key: "totalrefferal",
            width: "110px"
        },
        {
            name: "Team Business",
            selector: row => row["sum6040"],
            width: "130px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "190px"
        },
        {
            name: "Restaking",
            selector: row => row["restaking"],
            key: "restaking",
            width: "110px"
        },

        {
            name: "Member Detail",
            selector: row => row["restaking"],
            cell: (data) => <button className='btn btn-primary btn-sm' onClick={() => history('/userreport', { state: { address: data.faddress } })} id={data.faddress}>Details</button>,
            key: "restaking",
            width: 100
        }
    ];;
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };

    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div className="modal fade" id="bonusincome" tabindex="-1" aria-labelledby="bonusincomeLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="bonusincomeLabel">Give Bonus Reward</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='h5'>{bonusAddress}</div>
                                <hr />
                                <div className='h6'>Reff: {bonusRefadd}</div>
                                <div className='h6'>Reff %: {bonusRefper} %</div>
                                <div className='h6'>Reff $: ${bonusRefdollar}</div>
                                <hr />
                                <div className='d-flex'>
                                    <label className='h6' >Bonus Reward &nbsp;&emsp;</label>
                                    <div className="custom-control-lg  custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" defaultChecked={true} ref={bonusToggle} />
                                        <label className="custom-control-label" for="customCheck1"></label>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Bonus (%) </span>
                                    </div>
                                    <input type="text" className="form-control" value={bonusPer} onChange={(e) => setbonusPer(e.target.value)} />
                                </div>
                                <br />
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Dollar Locked($) </span>
                                    </div>
                                    <input type="text" className="form-control" value={bonusLD} onChange={(e) => setbonusLD(e.target.value)} />
                                </div>
                                <div className='p-1'>
                                    {bonusError ?
                                        <div className="alert alert-danger" role="alert">
                                            {bonusError}
                                        </div>
                                        : ""}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={bonusModal}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => bonusGive()}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_content_iner overly_inner ">
                    <div className="container-fluid p-0 ">
                        <div className="row">
                            <div className="col-12">
                            </div>
                            <div className="col-lg-12">
                                <div className="white_card mb_30 card_height_100">
                                    <div className="white_card_header pb-0">
                                        <div className="box_header m-0">
                                            <div className="main-title">
                                                <h3 className="m-0">List All Users</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white_card_body pt-0" style={{ marginTop: "50px" }}>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                        <div className="QA_section">
                                            <div className="QA_table mb-0 transaction-table">

                                                <div className="table-responsive">
                                                    <DataTableExtensions {...tableData}
                                                        print={false}
                                                        export={false}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            progressPending={loading}
                                                            defaultSortField="id"
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            paginationTotalRows={totalRows}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            pagination
                                                            highlightOnHover
                                                            paginationServer
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </section>
        </>
    );
}

export default ListUsers;