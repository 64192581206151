import React, { useState } from 'react';
import SidebarMenu from './SidebarMenu';
import './styles.css';
import Footer from './Footer';
import DashboardLeaderTable from './DashboardLeaderTable';
import { useNavigate } from "react-router-dom";
import DashboardHeader from './DashboardHeader';

const ListAllLeaders = (props) => {
    document.title = `Leaders - ${process.env.REACT_APP_APP_NAME}`
    const [isActive, setisActive] = useState(true);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-12">
                                {/* <div class="main-title mb_30">
                                    <h3 class="m-0">Welcome Back, Maria Pascle</h3>
                                </div> */}
                            </div>
                            <DashboardLeaderTable checklogin={props.checklogin} copyaddress={props.copyaddress} />
                        </div>
                    </div>
                </div>


                <Footer />
            </section>
        </>
    )
}

export default ListAllLeaders;