import React, { useState } from "react";
import SidebarMenu from "../SidebarMenu";

import '../styles.css';
import Footer from "../Footer";
import Clock from "./Clock";
import DashboardHeader from '.././DashboardHeader';
var parser = require('cron-parser');

const Crone = () => {
    document.title = `Crone - ${process.env.REACT_APP_APP_NAME}`
    const [isActive, setisActive] = useState(true);

    const toggleClass = () => {
        setisActive(!isActive)
    };
    var options = {
        tz: 'Asia/Kolkata'
      };

    const cronExpression = "0 1 * * *";
    const cronExpression1 = "0 1 * * 0";
    const cronExpression2 = "* * * * *";
    const interval = parser.parseExpression(cronExpression,options);
    const interval1 = parser.parseExpression(cronExpression1,options);
    const interval2 = parser.parseExpression(cronExpression2,options);
    var daily = interval.next().toISOString();
    var weekly = interval1.next().toISOString()
    var minute = interval2.next().toISOString()

    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">
                        <div class="white_card card_height_100 mb_30">
                            <div class="white_card_header">
                                <div class="box_header m-0">
                                    <div class="main-title">
                                        <h3 class="m-0">Crone</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="white_card_body pt-0">
                                <div class="QA_table mb-0 transaction-table">
                                    <div class="table-responsive">
                                        <table class="table  ">
                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                      
                                                    </td>
                                                    <td><h4>Cron Title</h4></td>
                                                    <td><h4>Cron Time</h4></td>
                                                    <td><h4>Cron Left Time</h4></td>

                                                    {/* <td><Clock deadline={"2023-06-25 16:10:49"} /></td> */}
                                                </tr>
                                                <tr>
                                                    <td scope="row">
                                                        <span class="buy-thumb">1</span>
                                                    </td>
                                                    <td><h4>Staking Reward (Daily)</h4></td>
                                                    <td><h4>01:00 AM</h4></td>
                                                    {/* <td><h4>2023-06-25 16:10:49</h4></td> */}
                                                    <td><Clock deadline={daily} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">
                                                        <span class="buy-thumb">2</span>
                                                    </td>
                                                    <td><h4>Daily Report (Daily)</h4></td>
                                                    <td><h4>01:00 AM</h4></td>
                                                    {/* <td><h4>2023-06-25 16:10:49</h4></td> */}
                                                    <td><Clock deadline={daily} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">
                                                        <span class="buy-thumb">2</span>
                                                    </td>
                                                    <td><h4>Weekly Reward (Weekly)</h4></td>
                                                    <td><h4>01:00 AM</h4></td>
                                                    {/* <td><h4>2023-06-25 16:10:49</h4></td> */}
                                                    <td><Clock deadline={weekly} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">
                                                        <span class="buy-thumb">3</span>
                                                    </td>
                                                    <td><h4>Update Transactions  (Minute)</h4></td>
                                                    <td><h4>Every Minute</h4></td>
                                                    {/* <td><h4>2023-06-25 16:10:49</h4></td> */}
                                                    <td><Clock deadline={minute} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i class="ti-angle-up"></i>
                </a>
            </div>
        </>
    )
}
export default Crone;