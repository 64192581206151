import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Footer from '../Footer';
import { useNavigate } from "react-router-dom";
import SidebarMenu from '../SidebarMenu';
import DashboardHeader from '.././DashboardHeader';

const Userdashboard = (props) => {
    document.title = `User dashboard - ${process.env.REACT_APP_APP_NAME}`
    const [Content, setContent] = useState('');
    const [Title, setTitle] = useState('');
    const [Status, setStatus] = useState(props.Setting.dashboard_popup);
    const [isActive, setisActive] = useState(true);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    const onChangeWebsite = async (e) => {
    
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "dashboard_popup_change", status: document.getElementById("course_1").checked, content: Content,title:Title })
            .then(res => {
                alert("data Updated successfully");
            })
    }
    useEffect(() => {
        setStatus(props.Setting.dashboard_popup);
        setContent(props.Setting.dashboard_popup_content);
        setTitle(props.Setting.dashboard_popup_title);
    }, [props])


    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-12">
                                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="page_title_left">
                                        <h3 class="mb-0">User dashboard</h3>
                                        {/* <!-- <p>Dashboard/Crypto currenct</p> --> */}
                                    </div>
                                    <div class="monitor_list_widget">
                                        <div class="simgle_monitor_list">
                                            <div class="simgle_monitor_count d-flex align-items-center">
                                                <span>Purchase</span>
                                                <div id="monitor_1"></div>
                                            </div>

                                        </div>
                                        <div class="simgle_monitor_list">
                                            <div class="simgle_monitor_count d-flex align-items-center">
                                                <span>Purchase</span>
                                                <div id="monitor_2"></div>
                                            </div>

                                        </div>
                                        <div class="simgle_monitor_list">
                                            <div class="simgle_monitor_count d-flex align-items-center">
                                                <span>Purchase</span>
                                                <div id="monitor_3"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* content here */}

                        <div className="card">
                            <div class="white_box mb_30">
                                <div class="row justify-content-center">
                                    <div class="col-lg-6">
                                        <div class="modal-content cs_modal">
                                            <div class="modal-header justify-content-center theme_bg_1">
                                                <h5 class="modal-title text_white">User dashboard</h5>
                                            </div>
                                            <div class="modal-body">
                                                <form>
                                                    <label className='lms_checkbox_1'>
                                                        Off/On
                                                    </label>
                                                    <div class="checkbox_wrap d-flex align-items-center">
                                                        <label class="lms_checkbox_1" for="course_1">
                                                            <input type="checkbox" id="course_1" defaultChecked={Status} onChange={(e) => setStatus(e.target.checked)} />
                                                            <div class="slider-check round"></div>
                                                        </label>
                                                    </div>

                                                    <label className='lms_checkbox my-3'>
                                                        Title
                                                    </label>

                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Enter title here"
                                                            autocomplete="on" defaultValue={props.Setting.dashboard_popup_title} onChange={(e) => setTitle(e.target.value)} />
                                                    </div>

                                                    <label className='lms_checkbox my-3'>
                                                        Message
                                                    </label>

                                                    <div class="form-group">
                                                        <textarea class="form-control" placeholder="Enter your message here"
                                                            autocomplete="on" defaultValue={props.Setting.dashboard_popup_content} onChange={(e) => setContent(e.target.value)} />
                                                    </div>

                                                    <button class="btn_1 full_width text-center" onClick={() => onChangeWebsite()} type="button">Submit</button>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <Footer />

            </section>

            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i class="ti-angle-up"></i>
                </a>
            </div>


        </>
    )
}

export default Userdashboard