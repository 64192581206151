import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import SidebarMenu from './SidebarMenu';
import { useNavigate } from "react-router-dom";

import "./styles.css";

import axios from 'axios';
import Web3 from 'web3';
import DashboardHeader from './DashboardHeader';
import SmartcontractlogTable from './SmartcontractlogTable';

const Dashboard = (props) => {

    // console.log("proooopoposposs", props);
    document.title = `Dashboard - ${process.env.REACT_APP_APP_NAME}`
    // console.log("dashboard",props);
    const [isActive, setisActive] = useState(true);
    const [cbalance, setcbalance] = useState(0);
    const [oxyBalance, setoxyBalance] = useState(0);
    const history = useNavigate();
    const [stakedata, setstakedata] = useState([]);
    const [Setting, setSetting] = useState([]);
    const [rate, setrate] = useState(0);
    const [Obalance, setObalance] = useState(0);
    const [BSobalance, setBSobalance] = useState(0);
    const [BSUbalance, setBSUbalance] = useState(0);
    const [BStBuytkn, setBStBuytkn] = useState(0);
    const [BSrcvbusd, setBSrcvbusd] = useState(0);
    const [BStSloxy, setBStSloxy] = useState(0);
    const [BSsBUSD, setBSsBUSD] = useState(0);
    const [tBStr, settBStr] = useState(0);

    const [thash, setthash] = useState('');
    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }

    const onGetSetting = async (e) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getsetting", setting: 1 })
            .then(res => {
                console.log(res.data[0]);
                setSetting(res.data[0]);
                if (res.data[0].live_rate_status) {
                    setrate(res.data[0].live_rate_content);
                } else {
                    setrate(0);
                }
            })
    }

    const stake = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", fromaddress: props.address, submethod: "dashData" })
            .then(res => {
                setstakedata(res.data);
            })
    }
    const balance = async () => {
        var web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_WEB3_PROVIDER));
        const token = new web3.eth.Contract(props.abi, process.env.REACT_APP_CONTRACT_ADDRESS);
        var balance = await token.methods.totalSupply().call()
        const accBalanceEth = web3.utils.fromWei(balance, "ether");
        setcbalance(Number(accBalanceEth).toFixed(2));
        //    console.log("xontreers",accBalanceEth);
    }
    const oxybalance = async () => {
        if (Setting) {
            var web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_WEB3_PROVIDER));
            const token = new web3.eth.Contract(JSON.parse(Setting.token_abi), Setting.token_address);
            const usdt_token_contract = new web3.eth.Contract(JSON.parse(Setting.busd_token_abi), Setting.busd_token_address);
            var balance = await token.methods.balanceOf(process.env.REACT_APP_WITHDRAW_CONTRACT).call();
            var bsusdt_balance = await usdt_token_contract.methods.balanceOf(Setting.buy_sell_contract_address).call();
            var buyselloxybalance = await token.methods.balanceOf(Setting.buy_sell_contract_address).call();
            var bsob = web3.utils.fromWei(buyselloxybalance, "ether");
            var bsub = web3.utils.fromWei(bsusdt_balance, "ether");
            setBSobalance(Number(bsob).toFixed(2))
            setBSUbalance(Number(bsub).toFixed(2))
            const accBalanceEth = web3.utils.fromWei(balance, "ether");
            setoxyBalance(Number(accBalanceEth).toFixed(2));


            //get data from buy sell smart contract 
            const buysell = new web3.eth.Contract(JSON.parse(Setting.buy_sell_contract_abi), Setting.buy_sell_contract_address);
            var total_token_buy = await buysell.methods.totalbuytoken().call();
            var ttb = web3.utils.fromWei(total_token_buy, "ether");

            var total_rcv_busd = await buysell.methods.totalreceivebusd().call();
            var trbusd = web3.utils.fromWei(total_rcv_busd, "ether");

            var total_token_sell = await buysell.methods.totalselltoken().call();
            var tts = web3.utils.fromWei(total_token_sell, "ether");

            var total_send_busd = await buysell.methods.totalsendbusd().call();
            var tsbusd = web3.utils.fromWei(total_send_busd, "ether");

            var total_transactions = await buysell.methods.getalltransaction().call();


            setBSsBUSD(Number(tsbusd));
            setBStSloxy(Number(tts));
            setBSrcvbusd(Number(trbusd));
            setBStBuytkn(Number(ttb));
            settBStr(Number(total_transactions));
            // console.log("rtotaltla buy token ", total_token_buy, ttb, trbusd, tts, tsbusd);
        }
        //    console.log("xontreers",accBalanceEth);
    }

    const insertSmartContarctLog = async (hash) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "smartContractLog", submethod: "insert", hash: hash })
            .then(res => {
                if (res.data.error) {
                    alert(res.data.error);
                    return false;
                }
                // console.log("insert res : ", res.data)
            })
    }
    const smartContract = async () => {

        const web3 = new Web3(Web3.givenProvider || `${process.env.REACT_APP_WEB3_PROVIDER}`);
        const network = Number(process.env.REACT_APP_CHAINID);
        var provider = window.ethereum;
        await provider.request({
            method: "eth_requestAccounts",
        });
        const accounts = await web3.eth.getAccounts();
        const chainid = await web3.eth.getChainId();

        // console.log(accounts[0], chainid, "accountsaccounts");
        if (chainid !== network) {
            alert("Connect to bsc smart chain")
            return '0'
        }

        const contract = new web3.eth.Contract(JSON.parse(Setting.claim_abi), Setting.contract_bytecode);

        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "userdataforsc" })
            .then(async res => {
                console.log("res", res.data);

                //array of user with address and balance
                var user = []
                user = res.data;
                // console.log("total : ", user)
                var userAddress = user.map((item) => { return item.address })
                var userBalance = user.map((item) => { return web3.utils.toWei(parseFloat(item.balance).toFixed(4), "ether") })
                // console.log("user", userAddress);
                // console.log("user balance  : ", userBalance)
                //-----------------//

                // "wdrtkn" is method 
                await contract.methods.updatebalance(userAddress, userBalance).send({ from: accounts[0] },
                    async function (err, transactionId) {
                        if (err) {
                            alert(err);
                            return false;
                        }
                        if (transactionId) {
                            await insertSmartContarctLog(transactionId)
                        }
                        setthash(transactionId)
                    });
            })
    }
    const Obal = async () => {
        var web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_WEB3_PROVIDER));
        const bln = await web3.eth.getBalance(process.env.REACT_APP_OWNER_ADDRESS);
        const utils = web3.utils.fromWei(bln, "ether");
        setObalance(Number(utils))
    }

    useEffect(() => {
        onGetSetting();
        stake();
        balance();
        Obal();
        if (Setting.token_abi) {
            oxybalance();
        }
        setInterval(() => {
            Obal()
        }, 30000);
        // withBalance();
    }, [props])

    const toggleClass = () => {
        setisActive(!isActive)
    };

    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />
            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div className="row">
                            <div className="col-12">
                                <div className="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="page_title_left">
                                        <button className='btn btn-primary btn-sm' onClick={() => smartContract()}>Smart Contract Call</button>
                                        <p className='text-dark'>{thash}</p>
                                        <h3 className="mb-0">Dashboard</h3>
                                    </div>
                                    <div className="monitor_list_widget">
                                        <div className="simgle_monitor_list">
                                            <div className="simgle_monitor_count d-flex align-items-center">
                                                <span>Live Rate</span>
                                                <div id="monitor_1"></div>
                                            </div>
                                            <h4 className="counter">$&nbsp;{rate}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-xl-12">
                                <div className="white_card  mb_30">
                                    <div className="white_card_header ">
                                        <div className="box_header m-0">
                                            <h3>Owner Address</h3>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="single_analite_content">
                                                    <h4>Address</h4>
                                                    <h5 className='text-white'>{process.env.REACT_APP_OWNER_ADDRESS} </h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Balance</h4>
                                                    <h3>{Obalance ? Obalance.toFixed(7) : '0'} BNB</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="white_card  mb_30">
                                    <div className="white_card_header ">
                                        <div className="box_header m-0">
                                            <h3>Statistics</h3>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Member</h4>
                                                    <h3>{stakedata.total_staker ? stakedata.total_staker : '0'} </h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Active Member</h4>
                                                    <h3>{stakedata.total_active_member ? stakedata.total_active_member : '0'}</h3>

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Burn</h4>
                                                    <h3>{stakedata.total_burn_token ? stakedata.total_burn_token : '0'} {process.env.REACT_APP_TICKER}</h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Upgrade Business</h4>
                                                    <h3>$ {stakedata.total_upgrade_business ? stakedata.total_upgrade_business : '0'}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="white_card  mb_30">
                                    <div className="white_card_header ">
                                        <div className="box_header m-0">
                                            <h3>Income</h3>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Level Income</h4>
                                                    <h3>$ {stakedata.total_level_income ? stakedata.total_level_income.toFixed(4) : '0'} </h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Reward Income</h4>
                                                    <h3>$ {stakedata.total_reward_income ? stakedata.total_reward_income.toFixed(2) : '0'}</h3>

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Leadership Income</h4>
                                                    <h3>$ {stakedata.total_leadership_income ? stakedata.total_leadership_income : '0'} </h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Flushed Income</h4>
                                                    <h3>$ {stakedata.total_flushed_income ? stakedata.total_flushed_income : '0'} </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="white_card  mb_30">
                                    <div className="white_card_header ">
                                        <div className="box_header m-0">
                                            <h3>Wallets</h3>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Main Wallet Balance</h4>
                                                    <h3>$ {stakedata.main_wallet_balance ? stakedata.main_wallet_balance.toFixed(2) : '0'} </h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Smart Contract Balance</h4>
                                                    <h3>{cbalance} {process.env.REACT_APP_TICKER}</h3>

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Withdrawal $</h4>
                                                    <h3>$ {stakedata.total_withdraw} </h3>

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Withdrawal </h4>
                                                    <h3>{stakedata.total_withdraw_token ? stakedata.total_withdraw_token.toFixed(6) : stakedata.total_withdraw_token} {process.env.REACT_APP_TICKER}</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="white_card  mb_30">
                                    <div className="white_card_header ">
                                        <div className="box_header m-0">
                                            <h3>Fees</h3>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Receive ($)</h4>
                                                    <h3>$ {stakedata.total_fee_dollar} </h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Receive </h4>
                                                    <h3>{stakedata.total_fee_token} {process.env.REACT_APP_TICKER}</h3>

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total BURN ($)</h4>
                                                    <h3>$ {stakedata.total_burn_dollar ? stakedata.total_burn_dollar.toFixed(2) : stakedata.total_burn_dollar} </h3>

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total BURN </h4>
                                                    <h3>{stakedata.total_fee_token ? stakedata.total_fee_token.toFixed(2) : stakedata.total_fee_token} {process.env.REACT_APP_TICKER}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="white_card  mb_30">
                                    <div className="white_card_header ">
                                        <div className="box_header m-0">
                                            <h3>Smart Contracts</h3>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="single_analite_content">
                                                    <h4>Token</h4>
                                                    <a target="_blank" href={`${process.env.REACT_APP_TAD_URL}/${process.env.REACT_APP_CONTRACT_ADDRESS}`}>
                                                        <h5 className='text-white'> {process.env.REACT_APP_CONTRACT_ADDRESS} </h5>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="single_analite_content">
                                                    <h4>Withdrawal</h4>
                                                    <a target="_blank" href={`${process.env.REACT_APP_TAD_URL}/${process.env.REACT_APP_WITHDRAW_CONTRACT}`}>
                                                        <h5 className='text-white'>{process.env.REACT_APP_WITHDRAW_CONTRACT}</h5></a>
                                                    <h5 className='text-white'>Balance: {oxyBalance} OX2</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="single_analite_content">
                                                    <h4>Buy/Sell </h4>
                                                    <a target="_blank" href={`${process.env.REACT_APP_TAD_URL}/${Setting.buy_sell_contract_address}`}>
                                                        <h5 className='text-white'> {Setting.buy_sell_contract_address} </h5>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>OX2</h4>
                                                    <h5 className='text-white'>Balance: {BSobalance} OX2</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>USDT</h4>
                                                    <h5 className='text-white'>Balance: {BSUbalance} USDT</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="single_analite_content">
                                                    <h4>Buy/Sell Owner </h4>
                                                    <a target="_blank" href={`${process.env.REACT_APP_TAD_URL}/${Setting.buy_sell_contract_owner}`}>
                                                        <h5 className='text-white'> {Setting.buy_sell_contract_owner} </h5>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <div className="single_analite_content">
                                                    <h4>Total OX2 Buy </h4>
                                                    <h5 className='text-white'>{BStBuytkn ? BStBuytkn.toFixed(4) : '0'} OX2</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="single_analite_content">
                                                    <h4>Total Sell OX2 </h4>
                                                    <h5 className='text-white'>{BStSloxy ? BStSloxy.toFixed(4) : '0'} OX2</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="single_analite_content">
                                                    <h4>Total Received USDT </h4>
                                                    <h5 className='text-white'>{BSrcvbusd ? BSrcvbusd.toFixed(4) : '0'} USDT</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="single_analite_content">
                                                    <h4>Total Send USDT </h4>
                                                    <h5 className='text-white'>{BSsBUSD ? BSsBUSD.toFixed(4) : '0'} USDT</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="single_analite_content">
                                                    <h4>Total Transaction (Buy/Sell) </h4>
                                                    <h5 className='text-white'>{tBStr}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="white_card  mb_30">
                                    <div className="white_card_header ">
                                        <div className="box_header m-0">
                                            <h3>Smart Contracts Logs</h3>
                                        </div>
                                    </div>
                                    <div className="white_card_body anlite_table p-0">
                                        <SmartcontractlogTable />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="page_title_left mb_10">

                                    </div>
                                    <div className="page_title_right">
                                        <div className="dropdown CRM_dropdown  mr_5 mb_10">

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />

            </section>

            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i className="ti-angle-up"></i>
                </a>
            </div>

        </>
    );
};

export default Dashboard;