import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
const UserReferral = (props) => {
    // console.log('yser',props.address);
    const [stakedata, setstakedata] = useState([]);
    // const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [total64, settotal64] = useState(0);
    const [total60, settotal60] = useState(0);
    const [total40, settotal40] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const fetchUsers = async (level) => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "teambusiness", address: props.address, level: level })
            .then(res => {
                // console.log("ressasass", res.data.total64);
                if (res.data == 'nodata' || res.data == 0) {
                    // setstakedata([]);
                    // settotal64(0)
                } else {
                    setstakedata(res.data.arrysent);
                    settotal64(res.data.total64.finalTotal)
                    settotal60(res.data.total64.fortyPer)
                    settotal40(res.data.total64.sixtyPer)
                }
                // setTotalRows(res.data.total);
            })
        setLoading(false);
    };

    const getSearchData = async (page) => {
        // console.log("perpage page : ", perPage , page)
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "UserReferralReward", search: searchInput, page: page, per_page: perPage })
            .then(res => {
                // console.log("res is : ", res.data);
                setstakedata(res.data.data)
                setLoading(false)
                // setTotalRows(res.data.total);
            })
    }
    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(0); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    // const stake = async () => {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", fromaddress: props.address, submethod: "level" })
    //         .then(res => {
    //             setstakedata(res.data);
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])

    const columns = [
        {
            name: "sr",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: 100
        },
        {
            name: "Position",
            selector: "position",
            cell: (data) => <span >{data.position ? data.position : '-'}</span>,
            key: "position",
            width: 100
        },
        {
            name: "address",
            selector: "address",
            key: "address",
            width: "300px"
        },
        {
            name: "reff_address",
            selector: "reff_address",
            key: "reff_address",
            width: "300px"
        },
        {
            name: "Staking",
            selector: "tstaking",
            cell: (data) => <span >$ {data.tstaking}</span>,
            key: "tstaking",
            width: "150px"
        },
        {
            name: "Team Business($)",
            selector: "teambusiness",
            cell: (data) => <span >$ {data.teambusiness}</span>,
            key: "teambusiness",
            width: "150px"
        },
        {
            name: "Total Member ",
            selector: "teamcount",
            key: "teamcount",
            width: "150px"
        },
        {
            name: "Activation ",
            selector: "activedatetime",
            key: "activedatetime",
            width: "200px"
        },
        // {
        //   name: "Round",
        //   selector: "round",
        //   key: "round",
        //   width:100
        // },
        {
            name: "Joining",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        }
    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    return (
        <>
            <div className="col-xl-12">
                <div className="white_card card_height_100 mb_30">
                    <div className="white_card_header">
                        <div className="box_header m-0">
                            <div className="main-title">
                            </div>
                        </div>
                    </div>
                    <div className="white_card_body">
                        <div className="QA_section">
                            <div className="white_box_tittle list_header">
                                <h4> List all Referral </h4>
                                <span>
                                    <select className="form-select form-select-lg mb-3" onChange={(e) => fetchUsers(Number(e.target.value))} aria-label=".form-select-lg example">
                                        <option selected value="0">1</option>
                                        <option value="1">2</option>
                                        <option value="2">3</option>
                                        <option value="3">4</option>
                                        <option value="4">5</option>
                                        <option value="5">6</option>
                                        <option value="6">7</option>
                                        <option value="7">8</option>
                                        <option value="8">9</option>
                                        <option value="9">10</option>
                                        <option value="10">11</option>
                                        <option value="11">12</option>
                                        <option value="12">13</option>
                                        <option value="13">14</option>
                                        <option value="14">15</option>
                                        <option value="15">16</option>
                                        <option value="16">17</option>
                                        <option value="17">18</option>
                                    </select>
                                </span>
                                <div className="box_right d-flex lms_block h4 justify-content-between w-50 ">
                                    <h3 className="widget-title border-0 text-left p-0" >Leadership: </h3>
                                    <h3>40: $ {total40 ? total40.toFixed(4) : '0'} </h3>
                                    <h3>60: $ {total60 ? total60.toFixed(4) : '0'} </h3>
                                </div>
                            </div>
                            <div className="QA_table mb_30">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noHeader
                                        defaultSortField="id"
                                        progressPending={loading}
                                        pagination
                                        paginationTotalRows={stakedata.length}
                                        // sortIcon={<SortIcon />}
                                        defaultSortAsc={true}
                                        highlightOnHover
                                        dense
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default UserReferral;