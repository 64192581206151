import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import MetisMenu from '@metismenu/react';
import MetisMenu from "@metismenu/react";
import "./metisMenu.css";
const SidebarMenu = (props) => {
    const history = useNavigate();
    if (!sessionStorage.getItem("DZPAZrclEQ3fDHSc")) {
        history("/");
    }
    const toggleClass = () => {
        props.toggleClass();
    };
    return (
        <>
            <nav
                className={
                    props.isActive
                        ? "sidebar dark_sidebar"
                        : window.outerWidth >= 766
                        ? "sidebar dark_sidebar mini_sidebar"
                        : "sidebar dark_sidebar active_sidebar"
                }
            >
                <div className="logo d-flex justify-content-between">
                    <a className="large_logo  h3 text-white " href="/dashboard">
                        TOKEN
                    </a>
                    <a className="small_logo h3 text-white " href="/dashboard">
                        TKN
                    </a>
                    <div className="sidebar_close_icon d-lg-none" onClick={toggleClass}>
                        <i className="ti-close" />
                    </div>
                </div>
                <ul id="sidebar_menu">
                    <li class="">
                        <a onClick={toggleClass} href="/dashboard" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/1.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Dashboard</span>
                            </div>
                        </a>
                    </li>
                    <MetisMenu>
                        <li class="">
                            <a class="has-arrow" href="#" aria-expanded="false">
                                <div class="nav_icon_small">
                                    <img src="assets/img/menu-icon/17.svg" alt="" />
                                </div>
                                <div class="nav_title">
                                    <span>Master</span>
                                </div>
                            </a>
                            <ul>
                                <li class="">
                                    <a class="has-arrow" href="#" aria-expanded="false">
                                        <div class="nav_icon_small">
                                            <img src="assets/img/menu-icon/17.svg" alt="" />
                                        </div>
                                        <div class="nav_title">
                                            <span>Popup</span>
                                        </div>
                                    </a>
                                    <ul>
                                        <li>
                                            <Link to="/website">Website</Link>
                                        </li>
                                        <li>
                                            <Link to="/userdashboard">User Dashboard</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/AllFAQ">
                                        <img src="assets/img/menu-icon/17.svg" alt="" />
                                        FAQs
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/liverate">
                                        <img src="assets/img/menu-icon/17.svg" alt="" />
                                        Live Rate
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/newsletter">
                                        <img src="assets/img/menu-icon/17.svg" alt="" />
                                        News Letter
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/socialMedia">
                                        <img src="assets/img/menu-icon/17.svg" alt="" />
                                        Social Media
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </MetisMenu>
                    <li class="">
                        <Link to="/listalluser" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/5.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Members</span>
                            </div>
                        </Link>
                    </li>
                    <li class="">
                        <Link to="/listallstaking" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/5.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Staking Deposit</span>
                            </div>
                        </Link>
                    </li>
                    <li class="">
                        <Link to="/listallwithdrawal" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/5.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Withdrawal</span>
                            </div>
                        </Link>
                    </li>
                    <MetisMenu>
                        <li>
                            <a class="has-arrow" aria-expanded="false">
                                <div class="nav_icon_small">
                                    <img src="assets/img/menu-icon/5.svg" alt="" />
                                </div>
                                <div class="nav_title">
                                    <span>Leader</span>
                                </div>
                            </a>
                            <ul>
                                <li>
                                    <Link to="/listallleaders">All Leaders</Link>
                                </li>
                                <li>
                                    <Link to="/leaderrole">Executive</Link>
                                </li>
                                <li>
                                    <Link to="/srexecutive">Sr.Executive</Link>
                                </li>
                                <li>
                                    <Link to="/coordinator">Co. Ordinator</Link>
                                </li>
                                <li>
                                    <Link to="/teamleader">Team Leader</Link>
                                </li>
                                <li>
                                    <Link to="/srteamleader">Sr. Team Leader</Link>
                                </li>
                                <li>
                                    <Link to="/director">Director</Link>
                                </li>
                                <li><Link to="/srdirector">Sr. Director</Link></li>
                                <li>
                                    <Link to="/countryhead">Country Head</Link>
                                </li>
                                <li>
                                    <Link to="/countryceo">Country CEO</Link>
                                </li>
                                <li>
                                    <Link to="/president">President</Link>
                                </li>
                            </ul>
                        </li>
                    </MetisMenu>
                    <MetisMenu>
                        <li class="">
                            <a class="has-arrow" href="#" aria-expanded="false">
                                <div class="nav_icon_small">
                                    <img src="assets/img/menu-icon/17.svg" alt="" />
                                </div>
                                <div class="nav_title">
                                    <span>Reports</span>
                                </div>
                            </a>
                            <ul>
                                <li>
                                    <Link to="/levelincome">Level Income</Link>
                                </li>
                                <li>
                                    <Link to="/stakingincome">Staking Income</Link>
                                </li>
                                <li>
                                    <Link to="/bonusrewards">Bonus Rewards</Link>
                                </li>
                                <li>
                                    <Link to="/leadershipincome">Leadership Income</Link>
                                </li>
                                <li>
                                    <Link to="/dailyreport">Daily Report</Link>
                                </li>
                                <li>
                                    <Link to="/listpendingrestake">Pending Restake</Link>
                                </li>
                                <li>
                                    <Link to="/restakeduser">Restaked Users</Link>
                                </li>
                                <li>
                                    <Link to="/FlushedIncome">Flushed Income</Link>
                                </li>
                                <li>
                                    <Link to="/pendingwithdrawal">Pending Withdrawal</Link>
                                </li>
                            </ul>
                        </li>
                    </MetisMenu>

                    <li class="">
                        <Link to="/maintenance" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/5.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Maintanence</span>
                            </div>
                        </Link>
                    </li>
                    <li class="">
                        <Link to="/crone" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/5.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Crone</span>
                            </div>
                        </Link>
                    </li>
                    <li class="">
                        <Link to="/listbuysale" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/5.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Buy / Sale</span>
                            </div>
                        </Link>
                    </li>
                    <li class="">
                        <Link to="/swap" aria-expanded="false">
                            <div class="nav_icon_small">
                                <img src="assets/img/menu-icon/5.svg" alt="" />
                            </div>
                            <div class="nav_title">
                                <span>Swap</span>
                            </div>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default SidebarMenu;
