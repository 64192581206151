import React, { useState, useEffect } from 'react';
import SidebarMenu from '../SidebarMenu';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import '../styles.css';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Footer from '../Footer';
import DashboardHeader from '.././DashboardHeader';
import parse from 'html-react-parser';

const AllFAQ = (props) => {
    document.title = `FAQs - ${process.env.REACT_APP_APP_NAME}`
    const [stakedata, setstakedata] = useState([]);
    const [isActive, setisActive] = useState(true);
    const history = useNavigate();
    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    const stake = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "faq", submethod: "get" })
            .then(res => {
                setstakedata(res.data);
            })
    }
    const deletFAQ = async (id) => {
        if (window.confirm("Are you sure?")) {
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "faq", submethod: "delete", id: id })
                .then(res => {
                    
                    stake();
                })
        }
    }
    useEffect(() => {
        stake();
    }, [props])
    const columns = [
        {
            name: "#",
            selector: "sr",
            key: "sr",
            width: "40px"
        },
        {
            name: "Title",
            selector: "title",
            key: "title",
            width: "400px"
        },
        {
            name: "Content",
            selector: "content",
            cell: (data) => <div className='' id={data.sr}>{parse(data.content)}</div>,
            key: "content",
            width: '580px'
        },
        {
            name: "Date Time",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        },
        {
            name: "",
            cell: (data) =>
                <div className='d-flex'>
                    <button className='btn btn-primary btn-sm' onClick={() => history('/UpdateFAQ', { state: { data: data } })} id={data.sr}>Update</button>&nbsp;
                    <button className='btn btn-danger btn-sm' onClick={() => deletFAQ(data._id)} id={data.sr}>Delete</button>
                </div>,
            key: "datetime",
            width: "200px"
        }
    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };

    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-12">
                                {/* <div class="main-title mb_30">
                                    <h3 class="m-0">Welcome Back, Maria Pascle</h3>
                                </div> */}
                            </div>

                            <div class="col-lg-12">
                                <div class="white_card mb_30 card_height_100">
                                    <div class="white_card_header pb-0">
                                        <div class="box_header m-0">
                                            <div class="page_title_left">
                                                <h3 class="m-0">FAQs </h3>
                                            </div>
                                            <div class="page_title_right">
                                                <Link type='button' to='/FAQs' className='btn btn-primary btn-sm'>Add New FAQ</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="white_card_body pt-0" style={{ marginTop: "50px" }}>
                                        <div class="QA_section">
                                            <div class="QA_table mb-0 transaction-table">

                                                <div class="table-responsive">
                                                    <DataTableExtensions {...tableData}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            defaultSortField="id"
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            pagination
                                                            highlightOnHover
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i class="ti-angle-up"></i>
                </a>
            </div>
        </>
    )
}

export default AllFAQ;