import React from "react";


function Footer() {
    return (
        <>

            {/* <div class="container">
                <div class="col-lg-12">
                    <div class="footer_iner text-center">
                        <p>2020 © Influence - Designed by <a href="/dashboard"> <i class="ti-heart"></i> </a><a href="#">
                            Ignite</a></p>
                    </div>
                </div>
            </div> */}



        </>
    )
}

export default Footer;