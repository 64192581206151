import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const SmartcontractlogTable = (props) => {

    const [loading, setLoading] = useState(false);
    const [smartcontarctLog, setSmartContractLog] = useState([]);
    const [smartcontarctLogLength, setSmartcontarctLogLength] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getSmartContractLog()
    }, []);

    const getSmartContractLog = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "smartContractLog", submethod: "get", page: currentPage, pageSize: perPage })
            .then(res => {
                if (res.data.error) {
                    alert(res.data.error);
                    return false;
                }
                setSmartContractLog(res.data.smartContractLog);
                setSmartcontarctLogLength(res.data.smartcontractLogLength);
            })
    }




    const handlePageChange = page => {
        setCurrentPage(page)
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const formatHash = (hash) => {
        return hash.substr(0, 5) + "..." + hash.substr(-5, 5)
    }

    const columns = [
        {
            name: "#",
            selector: row => row.id,
            key: "sr",
            width: "100px"
        },
        {
            name: "Hash",
            selector: row => row.hash,
            cell: (data) => <>{data.hash ? <><span onClick={(e) => props.copyaddress(data.hash)} id={data.hash}><i className='fa fa-copy' /></span>&nbsp;<span> {formatHash(data.hash)}</span></> :
                ''}</>,
            key: "reff_address",
            width: "430px"
        },
        {
            name: "Datetime",
            selector: row => row["dateTime"],
            key: "datetime",
            width: "190px"
        },
    ];

    const data = [
        ...smartcontarctLog
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };





    return (
        <>
            <div className="table-responsive">
                <DataTableExtensions {...tableData}>
                    <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        progressPending={loading}
                        defaultSortField="id"
                        // sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        paginationTotalRows={smartcontarctLogLength}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        pagination
                        highlightOnHover
                        paginationServer
                        dense
                    />
                </DataTableExtensions>
            </div>

        </>
    )
}
export default SmartcontractlogTable;