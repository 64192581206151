import React, { useState, useEffect } from 'react';
import SidebarMenu from '../SidebarMenu';
import '../styles.css';
import MaitainTextEditor from '../MaintainTextEditor';
import Footer from '../Footer';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import DashboardHeader from '.././DashboardHeader';
import { useNavigate } from 'react-router-dom';

function UpdateFAQ(props) {
    document.title = `Update FAQ - ${process.env.REACT_APP_APP_NAME}`
    const location = useLocation();
    const [isActive, setisActive] = useState(true);
    const [faqtitle, setfaqtitle] = useState(location.state.data.title);
    const [faqcontent, setfaqcontent] = useState(location.state.data.content);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        if (props && props.Setting) {
            setEditorLoaded(true);
        }
        setfaqtitle(location.state.data.title)
        setfaqcontent(location.state.data.content)
    }, [props])
    // console.log("location.state.address",location.state.data._id);
    const updatefaq = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "faq", submethod: "update",id:location.state.data._id,title:faqtitle,content:faqcontent })
            .then(res => {
                if (res.data.error) {
                    alert(res.data.error)
                } else {
                    alert("Update Successfully !");
                    history('/AllFAQ')

                }
            })
    }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    return (
        <>

            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />
            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div className="main_content_iner overly_inner ">
                    <div className="container-fluid p-0">
                        <div className="row" style={{ margin: "10px 0px 90px 0px" }}>
                            <div className="col-lg-12">
                                <div className="modal-content cs_modal">
                                    <div className="modal-header justify-content-center theme_bg_1">
                                        <h5 className="modal-title text_white">New FAQ</h5>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <label className='lms_checkbox my-3'>
                                               <strong> Title</strong>
                                            </label>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Enter title here"
                                                    autocomplete="on" onChange={(e) => setfaqtitle(e.target.value)} defaultValue={faqtitle}/>
                                            </div>
                                            <label className='lms_checkbox my-3'>
                                                <strong> Message </strong>
                                            </label>
                                            <div className="form-group">
                                                <MaitainTextEditor
                                                    name="description"
                                                    onChange={(data) => {
                                                        setfaqcontent(data)
                                                    }}
                                                    editorLoaded={editorLoaded}
                                                    value={faqcontent}
                                                />
                                            </div>
                                            <a className="btn_1 full_width text-center" type="button" onClick={() => updatefaq()}>Submit</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i className="ti-angle-up"></i>
                </a>
            </div>

        </>
    )
}


export default UpdateFAQ;