import React, { useState, useEffect } from 'react';
import SidebarMenu from '../SidebarMenu';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../styles.css';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Footer from '../Footer';
import DashboardHeader from '.././DashboardHeader';

const CoOrdinator = (props) => {
    document.title = `Co-Ordinator - ${process.env.REACT_APP_APP_NAME}`
    const [stakedata, setstakedata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isActive, setisActive] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const history = useNavigate();
    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    const fetchUsers = async page => {

        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "leadershipdata", page: page, per_page: perPage, position_id: "6233253ac084998d8909a3a2" })
            .then(res => {
                setstakedata(res.data.data);
                setTotalRows(res.data.total);

            })
        setLoading(false);
    };
    const handlePageChange = page => {

        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchLeaders", search: searchInput, page: page, per_page: perPage, position_id: "6233253ac084998d8909a3a2" })
                .then(res => {
                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }

    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])

    const data = [
        ...stakedata
    ];
    const columns = [
        {
            name: "#",
            selector: row => row["sr"],
            key: "sr",
            width: 100
        },

        {
            name: "Member",
            selector: row => row["faddress"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.faddress)} id={data.address}><i className='fa fa-copy' /></span>&nbsp;<span> {data.faddress}</span></>,
            key: "faddress",
            width: "430px"
        },
        {
            name: "Position",
            selector: row => row["position"],
            key: "position",
            width: 100
        },
        {
            name: "Strong Lag",
            selector: row => row["strongLag"],
            cell: (data) => <><span >$ {data.strongLag ? data.strongLag : '0'}</span></>,
            key: "strongLag",
            width: 100
        },
        {
            name: "Other Lag",
            selector: row => row["remainingLag"],
            cell: (data) => <><span >$ {data.remainingLag ? data.remainingLag : '0'}</span></>,
            key: "remainingLag",
            width: 100
        },
        {
            name: "Date",
            selector: row => row["datetime"],
            key: "datetime",
            width: 100
        }

    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };


    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-12">
                                {/* <div class="main-title mb_30">
                                    <h3 class="m-0">Welcome Back, Maria Pascle</h3>
                                </div> */}
                            </div>

                            <div class="col-lg-12">
                                <div class="white_card mb_30 card_height_100">
                                    <div class="white_card_header pb-0">
                                        <div class="box_header m-0">
                                            <div class="main-title">
                                                <h3 class="m-0">List All Co. Ordinator</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="white_card_body pt-0" style={{ marginTop: "50px" }}>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                        <div class="QA_section">
                                            <div class="QA_table mb-0 transaction-table">

                                                <div class="table-responsive">
                                                    <DataTableExtensions {...tableData}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            progressPending={loading}
                                                            defaultSortField="id"
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            paginationTotalRows={totalRows}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            pagination
                                                            highlightOnHover
                                                            paginationServer
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </section>





        </>
    )
}

export default CoOrdinator;