import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
const UserReferralReward = (props) => {
    // console.log('yser',props.address);
    const [stakedata, setstakedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const fetchUsers = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "UserReferralReward", fromaddress: props.address, page: page, per_page: perPage })
            .then(res => {
                // console.log("ress",res.data);
                setstakedata(res.data.data);
                setTotalRows(res.data.total);
            })
        setLoading(false);
    };
    const handlePageChange = page => {
        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        // console.log("perpage page : ", perPage , page)
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "UserReferralReward", search: searchInput, page: page, per_page: perPage })
            .then(res => {
                // console.log("res is : ", res.data);
                setstakedata(res.data.data)
                setLoading(false)
                setTotalRows(res.data.total);
            })
    }
    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    // const stake = async () => {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", fromaddress: props.address, submethod: "level" })
    //         .then(res => {
    //             setstakedata(res.data);
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])

    const columns = [
        {
            name: "#",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: "100px"
        },
        {
            name: "Member",
            selector: "fromaddress",
            key: "fromaddress",
            width: "180px"
        },
        {
            name: "Staking($)",
            selector: "staking",
            key: "staking",
            width: "180px"
        },
        {
            name: "Reward($)",
            selector: "income",
            key: "income",
            width: "150px"
        },
        {
            name: "Level ",
            selector: "income_level",
            key: "income_level",
            width: "100px"
        },
        // {
        //   name: "Round",
        //   selector: "round",
        //   key: "round",
        //   width:100
        // },
        {
            name: "Reward(%)",
            selector: "income_per",
            key: "income_per",
            width: "150px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        }, {
            name: "Flushed",
            selector: "flushed",
            cell: (data) => <strong className='text-danger text-uppercase' >{data.flushed}</strong>,
            key: "flushed",
            width: 100
        },

    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    return (
        <>
            <div className="col-xl-12">
                <div className="white_card card_height_100 mb_30">
                    <div className="white_card_header">
                        <div className="box_header m-0">
                            <div className="main-title">
                            </div>
                        </div>
                    </div>
                    <div className="white_card_body">
                        <div className="QA_section">
                            <div className="white_box_tittle list_header">
                                <h4> List all Referral  Reward</h4>
                                <div className="box_right d-flex lms_block">
                                </div>
                            </div>
                            <div className="QA_table mb_30">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noHeader
                                        defaultSortField="id"
                                        progressPending={loading}
                                        // sortIcon={<SortIcon />}
                                        defaultSortAsc={true}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        highlightOnHover
                                        dense
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserReferralReward;