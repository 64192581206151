import React, { useState, useEffect } from 'react';
import SidebarMenu from './SidebarMenu';
// import CountUp from 'react-countup';
// import { columns, data } from './data';
import "./styles.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from './Footer';
import axios from 'axios';
import UserStakingList from './userstaking';
import Userstakingreward from './Userstakingreward';
import UserReferralReward from './UserReferralReward';
import DashboardHeader from './DashboardHeader';
import UserReferral from './UserReferral';
import UserStatement from './UserStatement';
import UserWithdrawal from './UserWithdraw';
import UserReferralDatewise from './UserReferralDatewise';
import UserLeadershipReward from './UserLeadershipReward';
import UserBuySell from './UserBuySell';
import Web3 from 'web3';
import UserLeadershipRecord from './UserLeadershipRecord';
import UserFlushedIncome from './UserFlushedIncome';
import UserBonusReward from './UserBonusReward';

const UserAddress = (props) => {
    document.title = `User Report - ${process.env.REACT_APP_APP_NAME}`
    const location = useLocation();

    const [userdata, setuserdata] = useState([]);
    const [Balance, setBalance] = useState(0);
    const [userref, setuserref] = useState('');
    const [Setting, setSetting] = useState([]);
    const [userDataFromSM, setuserDataFromSM] = useState([]);
    const [teambusiness, setteambusiness] = useState(0);
    const [flushed_income, setflushed_income] = useState(0);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const onGetSetting = async (e) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getsetting", setting: 1 })
            .then(res => {
                // console.log("setting ", res.data);
                setSetting(res.data[0]);
            })
    }
    const onGetDashD = async () => {
        if (location.state.address) {
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getDashD", fromaddress: location.state.address })
                .then(res => {
                    setBalance(res.data.wallet_balance - res.data.total_withdrawal);
                    setuserdata(res.data);
                })
        }
    }
    const onGetUser = async (e) => {
        if (location.state.address) {
            axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getuser", address: location.state.address })
                .then(res => {
                    for (const iterator of res.data) {
                        setuserref(iterator.reff_address);
                        setteambusiness(iterator.team_business);
                        setflushed_income(iterator.flushed_income);
                    }
                })
        }
    }
    const getBSdata = async () => {
        if (location.state.address) {
            if (Setting.buy_sell_contract_address) {
                var web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_WEB3_PROVIDER));
                const buysell = new web3.eth.Contract(JSON.parse(Setting.buy_sell_contract_abi), Setting.buy_sell_contract_address);
                var bsusdt_balance = await buysell.methods.userstate(location.state.address).call();
                // console.log("bsusdt_balance", bsusdt_balance);
                setuserDataFromSM({
                    totalbuytoken: Number(web3.utils.fromWei(bsusdt_balance._totalbuytoken, "ether")).toFixed(2),
                    totalselltoken: Number(web3.utils.fromWei(bsusdt_balance._totalselltoken, "ether")).toFixed(2),
                    totalsendusdt: Number(web3.utils.fromWei(bsusdt_balance._totalsendbusd, "ether")).toFixed(2),
                    totalrcvusdt: Number(web3.utils.fromWei(bsusdt_balance._totalreceivebusd, "ether")).toFixed(2)
                });
            }
        }
    }
    useEffect(() => {
        onGetSetting()
    }, [])
    useEffect(() => {
        onGetDashD();
        onGetUser()
    }, [props])

    useEffect(() => {
        getBSdata()
    }, [Setting])
    const [isActive, setisActive] = useState(true);

    const toggleClass = () => {
        setisActive(!isActive)
    };


    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />
            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div class="row">
                    <div class="col-12">
                        <div class="main-title mb_30">

                        </div>
                    </div>
                    <div class="col-lg-12 ">
                        <div class="white_card card_height_100 mb_30">
                            <div class="white_card_header pb-0">
                                <div class="box_header m-0">
                                </div>
                            </div>
                            <div class="white_card_body">
                                <div class="media mb_30 border_bottom_1px pb-3">
                                    <div class="media-body pl-5">
                                        <form id="Form1" class="form-horizontal" role="form" runat="server">
                                            <div class="row">
                                                <div class="input-group" style={{ width: "60%" }}>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id=""
                                                            style={{ background: "none", border: "none" }}>User Address:</span>
                                                        &nbsp;&nbsp;&nbsp;
                                                    </div>
                                                    <input type="text" class="form-control" readOnly style={{ border: "none" }}
                                                        placeholder="....." value={location.state.address} />
                                                </div>
                                                <div class="input-group mt-2" style={{ width: "60%" }}>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id=""
                                                            style={{ background: "none", border: "none" }}>Ref. Address:</span>
                                                    </div>
                                                    &nbsp;&nbsp;&nbsp; &nbsp;<input type="text" class="form-control" readOnly
                                                        style={{ border: "none" }} value={userref} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="white_card_body anlite_table p-0">
                        <div class="row">
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Staking </h4>
                                    <h3>$  {userdata.total_staking ? userdata.total_staking : '0'}</h3>

                                </div>
                            </div>
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4> Total Balance</h4>
                                    <h3>$<span class="counter">{Balance ? Balance.toFixed(2) : '0'} </span> </h3>
                                </div>
                            </div>
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Withdraw</h4>
                                    <h3>$<span class="counter">{userdata.total_withdrawal ? userdata.total_withdrawal : '0'}</span> </h3>
                                </div>
                            </div>
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Pending Referral </h4>
                                    <h3>$<span class="counter">{userdata.total_referral_p ? userdata.total_referral_p : '0'}</span> </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid mt-5">
                    <div class="white_card_body anlite_table p-0">
                        <div class="row">
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4>Rank</h4>
                                    <h3>{userdata.position ? userdata.position : 'No Rank'} </h3>

                                </div>
                            </div>
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Staking Reward </h4>
                                    <h3>$ <span class="counter">{userdata.staking_reward ? userdata.staking_reward : '0'}</span> </h3>

                                </div>
                            </div>
                            <div class="col-lg-2 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Leadership Reward</h4>
                                    <h3>$<span class="counter">{userdata.total_weekly ? userdata.total_weekly : '0'}</span> </h3>

                                </div>
                            </div>
                            <div class="col-lg-2 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Referral  Reward</h4>
                                    <h3>$<span class="counter">{userdata.total_referral ? userdata.total_referral.toFixed(4) : '0'}</span> </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Flushed Income</h4>
                                    <h3>$<span class="counter">{flushed_income ? flushed_income.toFixed(4) : '0'}</span> </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid mt-5">
                    <div class="white_card_body anlite_table p-0">
                        <div class="row">
                            <div class="col-lg-2 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Buy OX2</h4>
                                    <h3>{userDataFromSM.totalbuytoken ? userDataFromSM.totalbuytoken : '0'} OX2 </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Sell OX2 </h4>
                                    <h3>{userDataFromSM.totalselltoken ? userDataFromSM.totalselltoken : '0'} OX2 </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Receive USDT</h4>
                                    <h3>{userDataFromSM.totalrcvusdt ? userDataFromSM.totalrcvusdt : '0'} USDT</h3>
                                </div>
                            </div>
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4>Total Send USDT</h4>
                                    <h3>{userDataFromSM.totalsendusdt ? userDataFromSM.totalsendusdt : '0'} USDT </h3>
                                </div>
                            </div>
                            <div class="col-lg-3 col text-center">
                                <div class="single_analite_content">
                                    <h4>Team Business</h4>
                                    <h3>{teambusiness ? teambusiness : '0'} USDT </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="main_content_iner ">
                    <div class="container-fluid p-0">
                        <div class="row justify-content-center">
                            <UserStakingList address={location.state.address} checklogin={props.checklogin} />
                            <Userstakingreward address={location.state.address} checklogin={props.checklogin} />
                            <UserLeadershipReward address={location.state.address} checklogin={props.checklogin} />
                            <UserLeadershipRecord address={location.state.address} checklogin={props.checklogin} />
                        </div>
                    </div>
                </div>
                <div class="main_content_iner ">
                    <div class="container-fluid p-0">
                        <div class="row justify-content-center">
                            <UserReferralReward address={location.state.address} checklogin={props.checklogin} />
                            <UserBonusReward address={location.state.address} checklogin={props.checklogin} />
                            <UserStatement address={location.state.address} checklogin={props.checklogin} />
                            <UserReferral address={location.state.address} checklogin={props.checklogin} />
                            <UserReferralDatewise address={location.state.address} checklogin={props.checklogin} />
                            <UserWithdrawal address={location.state.address} checklogin={props.checklogin} />
                            <UserBuySell address={location.state.address} checklogin={props.checklogin} />
                            <UserFlushedIncome address={location.state.address} checklogin={props.checklogin} />
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}
export default UserAddress;