import React, { useState, useEffect } from 'react';
import SidebarMenu from './SidebarMenu';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import DashboardHeader from './DashboardHeader';
import FileSaver from "file-saver";
import XLSX from "xlsx";

const ListAllWithdrawal = (props) => {
    document.title = `Withdrawal - ${process.env.REACT_APP_APP_NAME}`
    const [stakedata, setstakedata] = useState([]);
    const [isActive, setisActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const fetchUsers = async page => {

        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allwithdraw", page: page, per_page: perPage })
            .then(res => {
                setstakedata(res.data.data);
                setTotalRows(res.data.total);

            })
        setLoading(false);
    };
    const handlePageChange = page => {

        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchAllWithdraw", search: searchInput, page: page, per_page: perPage })
                .then(res => {

                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }
    // ******** XLSX with object key as header *************
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allwithdraw", page: 1, per_page: 10000 })
            .then(res => {
                var newData = []
                for (let i = 0; i < res.data.data.length; i++) {
                    const e = res.data.data[i];
                    var datas = {
                        "sr": e.sr,
                        "Hash":e.fhash,
                        "Address": e.faddress,
                        "Total": e.total,
                        "Rate": e.rate,
                        "Fee ($)": e.fee_dollar,
                        "Fee (Token)": e.fee_token,
                        "Burn Per(%)": e.burn_per,
                        "Total Burned ($)": e.total_burn_dollar,
                        "Total Burn (Token)": e.total_burn_token,
                        "Total Receive": e.total_receive,
                        "Date Time": e.datetime,
                        "Req. Date Time":e.requestdatetime
                    };
                    newData.push(datas)
                }
                const ws = XLSX.utils.json_to_sheet(newData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, "AllWithdrawal" + fileExtension);
            })

    };
    // const stake = async () => {
    //     setLoading(true)
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "allwithdraw" })
    //         .then(res => {
    //             setstakedata(res.data);
    //             setLoading(false)
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])
    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    const columns = [
        {
            name: "#",
            selector: row => row["sr"],
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: row => row["status"],
            key: "status",
            width: "100px"
        },
        {
            name: "Hash",
            selector: row => row["hash"],
            cell: (data) => <><a target="_blank" href={`${process.env.REACT_APP_TXN_URL}${data.fhash}`}>{data['hash']}</a></>,
            key: "hash",
            width: "220px"
        },
        {
            name: "Address",
            selector: row => row["address"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.faddress)} ><i className='fa fa-copy' /></span>&nbsp;<span> {data.address}</span></>,
            key: "address",
            width: "220px"
        },
        {
            name: "Total",
            selector: row => row["total"],
            key: "total",
            width: "120px"
        },

        {
            name: "Rate",
            selector: row => row["rate"],
            key: "rate",
            width: "120px"
        },
        {
            name: "Fee($)",
            selector: row => row["fee_dollar"],
            key: "fee_dollar",
            width: "120px"
        },

        {
            name: "Fee(token)",
            selector: row => row["fee_token"],
            key: "fee_token",
            width: "120px"
        },

        {
            name: "Burn(%)",
            selector: row => row["burn_per"],
            key: "burn_per",
            width: "120px"
        },
        {
            name: "Burn($)",
            selector: row => row["total_burn_dollar"],
            key: "total_burn_dollar",
            width: "120px"
        },
        {
            name: "Burn(Token)",
            selector: row => row["total_burn_token"],
            key: "total_burn_token",
            width: "120px"
        },
        {
            name: "Recieved",
            selector: row => row["total_receive"],
            key: "total_receive",
            width: "120px"
        },
        {
            name: "Req.$",
            selector: row => row["requesteddollar"],
            key: "requesteddollar",
            width: "120px"
        },
        {
            name: "Req.Token",
            selector: row => row["requestedtoken"],
            key: "requestedtoken",
            width: "120px"
        },
        {
            name: "Req.Rate",
            selector: row => row["requestedliverate"],
            key: "requestedliverate",
            width: "120px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "200px"
        },
        {
            name: "Req.Datetime",
            selector: row => row["requestdatetime"],
            key: "requestdatetime",
            width: "200px"
        }

    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    const toggleClass = () => {
        setisActive(!isActive)
    };



    return (
        <>

            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="white_card card_height_100 mb_30">
                                    <div class="white_card_header">
                                        <div class="white_box_tittle list_header mb-0">
                                            <h4>All Withdrawals</h4>
                                            <div class="box_right d-flex lms_block">
                                                {/* <div class="serach_field_2">
                                                    <div class="search_inner">
                                                        <form Active="#">
                                                            <div class="search_field">
                                                                <input type="text" placeholder="Search content here..." />
                                                            </div>
                                                            <button type="submit"> <i class="ti-search"></i> </button>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="add_button ml-10">
                                                    <a href="#" data-toggle="modal" data-target="#addcategory" class="btn_1">Add
                                                        New</a>
                                                </div> */}
                                            </div>
                                            <button className='btn btn-primary' onClick={(e) => exportToCSV()}>Export to xls</button>
                                        </div>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                            <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                    </div>
                                    <div class="white_card_body">
                                        <div class="QA_section">
                                            <div class="QA_table mb_30">

                                                <DataTableExtensions {...tableData}>
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        noHeader
                                                        progressPending={loading}
                                                        defaultSortField="id"
                                                        // sortIcon={<SortIcon />}
                                                        defaultSortAsc={true}
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        pagination
                                                        highlightOnHover
                                                        paginationServer
                                                        dense
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </section>



        </>
    )
}


export default ListAllWithdrawal;