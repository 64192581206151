import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
const UserReferralDatewise = (props) => {
    // console.log('yser',props.address);
    const [stakedata, setstakedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [total64, settotal64] = useState(0);
    const [loading, setLoading] = useState(false);
    const [Fdate, setFdate] = useState('');
    const [Sdate, setSdate] = useState('');
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const fetchUsers = async () => {
        // console.log("ress", !Sdate, !Fdate);
        if (Sdate && Fdate) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "ReferralsDate", address: props.address, Sdate: Sdate, Fdate: Fdate })
                .then(res => {
                    console.log("dasdsh ", res.data)
                    if (res.data == 'nodata' || res.data == 0) {
                        // setstakedata([]);
                        // settotal64(0)
                    } else {
                        setstakedata(res.data.data);
                        settotal64(res.data.total)
                    }
                    // setTotalRows(res.data.total);
                })
            setLoading(false);
        } else {
            // alert("Select dates ")
        }
    };

    const getSearchData = async (page) => {
        // console.log("perpage page : ", perPage , page)
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "UserReferralReward", search: searchInput, page: page, per_page: perPage })
            .then(res => {
                // console.log("res is : ", res.data);
                setstakedata(res.data.data)
                setLoading(false)
                setTotalRows(res.data.total);
            })
    }
    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            // getSearchData(currentPage);
        }
        else {
            fetchUsers(0); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    // const stake = async () => {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", fromaddress: props.address, submethod: "level" })
    //         .then(res => {
    //             setstakedata(res.data);
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])

    const columns = [
        {
            name: "sr",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: 100
        },
        {
            name: "Position",
            selector: "position",
            cell: (data) => <span >{data.position ? data.position : '-'}</span>,
            key: "position",
            width: 100
        },
        {
            name: "address",
            selector: "address",
            key: "address",
            width: "300px"
        },
        {
            name: "reff_address",
            selector: "reff_address",
            key: "reff_address",
            width: "300px"
        },
        {
            name: "Staking",
            selector: "tstaking",
            cell: (data) => <span >$ {data.tstaking}</span>,
            key: "tstaking",
            width: "150px"
        },
        // {
        //     name: "Team Business($)",
        //     selector: "teambusiness",
        //     cell: (data) => <span >$ {data.teambusiness}</span>,
        //     key: "teambusiness",
        //     width: "150px"
        // },
        {
            name: "Team Business",
            selector: "teambusiness",
            cell: (data) => <span>$ {data.teambusiness ? data.teambusiness.toFixed(6) : data.teambusiness}</span>,
            key: "teambusiness",
            width: "150px"
        },
        {
            name: "Total Member ",
            selector: "teamcount",
            key: "teamcount",
            width: "150px"
        },
        {
            name: "Activation ",
            selector: "activedatetime",
            key: "activedatetime",
            width: "200px"
        },
        // {
        //   name: "Round",
        //   selector: "round",
        //   key: "round",
        //   width:100
        // },
        {
            name: "Joining",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        }
    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    return (
        <>
            <div className="col-xl-12">
                <div className="white_card card_height_100 mb_30">
                    <div className="white_card_header">
                        <div className="box_header m-0">
                            <div className="main-title">
                            </div>
                        </div>
                    </div>
                    <div className="white_card_body">
                        <div className="QA_section">
                            <div className="white_box_tittle list_header">
                                <h4> Leg Bussiness Report </h4>
                                <div>
                                    First date : <input type="date" onChange={(e) => setFdate(e.target.value)} />
                                    Second date : <input type="date" onChange={(e) => setSdate(e.target.value)} />
                                    <button className='btn btn-sm btn-primary' onClick={() => fetchUsers()}>Find</button>
                                </div>
                            </div>
                            <div className="QA_table mb_30">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noHeader
                                        defaultSortField="id"
                                        progressPending={loading}
                                        // sortIcon={<SortIcon />}
                                        defaultSortAsc={true}
                                        pagination
                                        paginationTotalRows={stakedata.length}
                                        // onChangeRowsPerPage={handlePerRowsChange}
                                        // onChangePage={handlePageChange}
                                        highlightOnHover
                                        dense
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserReferralDatewise;