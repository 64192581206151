import React from "react";


export const columns = [
          
  {
    name: "Hash#",
    selector: "hash",
    key: "hash",
    width: 100
  },
  
  {
    name: "F.Add",
    selector: "fromaddress",
    key: "fromaddress",
    width: 100
  },
  {
    name: "Invest($)",                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
    selector: "total_investment",
    key: "total_investment",
    width: 100
  },
  
  {
    name: "Token Rate ",
    selector: "token_rate",
   key: "token_rate",
   width: 100
  },
  
  {
    name: "Tokens",
    selector: "total_token",
    width:100
  },
  
  {
    name: "Datetime",
    selector: "datetime",
    key: "datetime",
    width: 100
  },
  {
    name: "End Datetime",
    selector: "end_datetime",
    key: "end_datetime",
    width: 100
  }
  ,
  // {
  //   name: "Status",
  //   selector: "status",
  //   key: "status",
  //   width: 100
  // },
  {
      name: "Type",
      selector: "t_type",
      key: "t_type",
      width: 100
    }
];

export const data = [
  {
    id: 1,
    fadd: "0x384e...56fbe8",
    invest: "150 $",
    reward: "90 $",
    level: "one",
    levelper: "10%",
    timestamp: "12:45:30 AM",
    status: "pending"
  },
  {
    id: 2,
    
    fadd: "0xa92a...83ddba",
   
   invest: "180 $",
   reward: "190 $",
   level: "two",
   levelper: "50%",
       
   timestamp: "2:05:30 PM",
   status: "pending"
  
  },
  {
    id: 3,
    
    fadd: "0xa92a...83ddba",
   
   invest: "110 $",
   reward: "40 $",
   level: "one",
   levelper: "35%",
       
   timestamp: "8:34:20 AM",
   status: "pending"
  
  },
  {
    id: 4,
    
    fadd: "0xa92a...83ddba",
    invest: "70 $",
    reward: "140 $",
    level: "three",
    levelper: "5%",
        
    timestamp: "18:44:26 PM",
    status: "pending"
    
  },
 
  {
    id: 18,
    
    fadd: "0xa92a...83ddba",
   
   invest: "110 $",
   reward: "40 $",
   level: "one",
   levelper: "35%",
       
   timestamp: "8:34:20 AM",
   status: "pending"
  
  }];









