import React, { useState, useEffect } from "react";
import SidebarMenu from "../SidebarMenu";

import '../styles.css';
import Footer from "../Footer";
import DashboardHeader from '../DashboardHeader';
import axios from "axios";


const SocialMedia = (props) => {
    document.title = `Social Media - ${process.env.REACT_APP_APP_NAME}`
    const [isActive, setisActive] = useState(true);
    const [social_media, setsocial_media] = useState('');
    const getdata = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "social_media", submethod: "get" })
            .then(res => {
                setsocial_media(res.data);
            })
    }
    useEffect(() => {
        getdata();
    }, [props])
    const toggleClass = () => {
        setisActive(!isActive)
    };

    const onUpdate = async (id) => {
      
        var datacnt = document.getElementById(id).value;
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "social_media", submethod: "update", id: id, url: datacnt })
            .then(res => {
                // console.log(res.data);
                if (res.data === 1) {
                    alert("updated successfully");
                } else {
                    alert("error");
                }
                getdata()
            })
    }
    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">
                        <div class="white_card card_height_100 mb_30">
                            <div class="white_card_header">
                                <div class="box_header m-0">
                                    <div class="main-title">
                                        <h3 class="m-0">SocialMedia</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="white_card_body pt-0">
                                <div class="QA_table mb-0 transaction-table">
                                    <div class="table-responsive">
                                        <table class="table  ">
                                            <tbody>
                                                <tr>
                                                    <td><h4>Social Media</h4></td>
                                                    <td><h4>Link</h4></td>
                                                    <td></td>
                                                </tr>
                                                {social_media.length && social_media.map((item, key) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                <div class="form-group"><input id={item._id}  type="text" class="form-control"  defaultValue={item.url} /></div>
                                                            </td>
                                                            <td><button type="button" className="btn btn-primary btn-sm" onClick={(e) => { onUpdate(item._id) }}>Update</button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i class="ti-angle-up"></i>
                </a>
            </div>
        </>
    )
}
export default SocialMedia;