import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import axios from 'axios';
import DashboardHeader from './DashboardHeader';
import { useNavigate } from "react-router-dom";
import SidebarMenu from './SidebarMenu';
const AddDailyReward = (props) => {
    const [address, setaddress] = useState(null);
    const [datetime, setdatetime] = useState(null);
    const [error, seterrpr] = useState(null);
    const [isActive, setisActive] = useState(true);
    const [adderror, setadderror] = useState(false);

    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }

    const toggleClass = () => {
        setisActive(!isActive)
    };
    const getUserData = async (account) => {
        if (!account) {
            return
        }
        await axios.post(process.env.REACT_APP_BACKEND_LINK, { method: "getuser", address: account.toLowerCase() })
            .then(res => {
                if (!res.data.length) {
                    seterrpr("Address not found")
                    setadderror(true)
                    return
                }
                setadderror(false)
                seterrpr("")
            })
    };

    const addtr = async () => {
        const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        if (!addressRegex.test(address) || adderror) {
            seterrpr("Enter valid Address")
            return
        }
        console.log("datetime", datetime);
        if (!datetime) {
            seterrpr("Enter valid Datetime")
            return
        }
        seterrpr("")
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            method: "addDailyReward",
            address: address.toLowerCase(),
            datetime: datetime
        }).then(res => {
            console.log("res",res.data);
            if (res.data.data.error) {
                seterrpr(res.data.data.error)
                return
            }
            seterrpr(res.data.data.data) 
        })
    }
    useEffect(() => {
        getUserData(address)
    }, [address])
    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />
            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">
                        <div className="col-xl-12">
                            <div className="white_card card_height_100 mb_30">
                                <div className="white_card_header">
                                    <div className="box_header m-0">
                                        <div className="main-title">
                                        </div>
                                    </div>
                                </div>
                                <div className="white_card_body">
                                    <div className="QA_section">
                                        <div className="white_box_tittle list_header">
                                            <h4>Add Transaction</h4>
                                            <div className="box_right d-flex lms_block">
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div class="col-lg-6 form-group">
                                                <h5>Address</h5>
                                                <input type="text" class="form-control" placeholder="Address" autocomplete="on" onChange={(e) => setaddress(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Date</h5>
                                                <input type="date" class="form-control" autocomplete="on" onChange={(e) => setdatetime(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <div className='pt-2'>
                                                    <span className='text-danger'>{error}</span>
                                                    <br />
                                                    <button type='button' class="btn_6 full_width text-center" onClick={() => addtr()} >Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    );
};

export default AddDailyReward;