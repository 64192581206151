import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';


const DashboardLeaderTable = (props) => {
    // console.log(props);
    const [stakedata, setstakedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')

    }
    const fetchUsers = async page => {

        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "listleader", page: page, per_page: perPage })
            .then(res => {
                setstakedata(res.data.data);
                setTotalRows(res.data.total);

            })
        setLoading(false);
    };
    const handlePageChange = page => {

        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchListLeader", search: searchInput, page: page, per_page: perPage })
                .then(res => {

                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }

    // const stake = async () => {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "listleader" })
    //         .then(res => {
    //             // console.log("dashallstake", res.data);
    //             setstakedata(res.data);
    //             // console.log("sas", stakedata);
    //         })
    // }
    // useEffect(() => {
    //     stake();
    // }, [props])
    useEffect(() => {
        // stake();

        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])

    const columns = [
        {
            name: "#",
            selector: row => row["sr"],
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: row => row["status"],
            key: "status",
            width: "90px"
        },

        {
            name: "Member",
            selector: row => row["faddress"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.faddress)} id={data.address}><i className='fa fa-copy' /></span>&nbsp;<span> {data.faddress}</span></>,
            key: "faddress",
            width: "430px"
        },
        {
            name: "Referral Member",
            selector: row => row["reff_address"],
            key: "reff_address",
            width: "150px"
        },
        {
            name: "Leadership",
            selector: row => row["position"],
            key: "position",
            width: "110px"
        },
        // {
        //     name: "Wallet Balance",
        //     selector: row => row["wallet_balance"],
        //     key: "wallet_balance",
        //     width: "140px"
        // },
        // {
        //     name: "Total Staking",
        //     selector: row => row["total_staking"],
        //     key: "total_staking",
        //     width: "130px"
        // },
        // {
        //     name: "Total Withdrawal",
        //     selector: row => row["total_withdrawal"],
        //     width: "150px"
        // },
        // {
        //     name: "Team Business",
        //     selector: row => row["sum6040"],
        //     width: "130px"
        // },
        {
            name: "Strong Lag",
            selector: row => row["strongLag"],
            width: "130px"
        },
        {
            name: "Other Lag",
            selector: row => row["remainingLag"],
            width: "130px"
        },
        {
            name: "P.Datetime",
            selector: row => row["p_datetime"],
            key: "p_datetime",
            width: "200px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "200px"
        },

        {
            name: "Restaking",
            selector: row => row["restaking"],
            key: "restaking",
            width: "100px"
        },
        {
            name: "",
            selector: row => row["restaking"],
            cell: (data) => <button className='btn btn-primary btn-sm' onClick={() => history('/userreport', { state: { address: data.faddress } })} id={data.faddress}>Details</button>,
            key: "restaking",
            width: "100px"
        }
    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    return (
        <>
            <div class="col-lg-12">
                <div class="white_card mb_30 card_height_100">
                    <div class="white_card_header pb-0">
                        <div class="box_header m-0">
                            <div class="main-title">
                                <h3 class="m-0">List All Leaders</h3>
                            </div>
                        </div>
                    </div>
                    <div class="white_card_body pt-0" style={{ marginTop: "50px" }}>
                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                        <div class="QA_section">
                            <div class="QA_table mb-0 transaction-table">

                                <div class="table-responsive">
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            progressPending={loading}
                                            defaultSortField="id"
                                            // sortIcon={<SortIcon />}
                                            defaultSortAsc={true}
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            pagination
                                            highlightOnHover
                                            paginationServer
                                            dense
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DashboardLeaderTable;