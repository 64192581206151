import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
import SidebarMenu from './SidebarMenu';
import DashboardHeader from './DashboardHeader';


const ChangePassword = (props) => {
    document.title = `Change Password - ${process.env.REACT_APP_APP_NAME}`
    const [Content, setContent] = useState('');
    const [Status, setStatus] = useState(props.Setting.live_rate_status);
    const [isActive, setisActive] = useState(true);
    const [AdminData, setAdminData] = useState('');
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    const getAdminData = async () =>{
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "adminLogin",submethod:"get" })
        .then(res => {
            setAdminData(res.data[0]);
        })
    }
    
     const onChangePassword = async (e) => {
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "adminLogin",submethod:"update", newpassword: Content })
            .then(res => {
                if(res.data===0){
                    alert("data Updated successfully");
                    
                }else{
                    alert("error occured")
                }
                getAdminData();
            })
    }
    useEffect(() => {
        getAdminData()
        setStatus(props.Setting.live_rate_status);
        setContent(props.Setting.live_rate_content)
    }, [props])



    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-12">
                                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="page_title_left">
                                        <h3 class="mb-0">Change Password</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* content here */}

                        <div className="card">
                            <div class="white_box mb_30">
                                <div class="row justify-content-center">
                                    <div class="col-lg-6">
                                        <div class="modal-content cs_modal">
                                            <div class="modal-header justify-content-center theme_bg_1">
                                                <h5 class="modal-title text_white">Change Password</h5>
                                            </div>
                                            <div class="modal-body">
                                                <form>
                                                    <label className='lms_checkbox my-3'>
                                                        Current Password
                                                    </label>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Enter your message here"
                                                            autocomplete="on" defaultValue={AdminData.password} readOnly />
                                                    </div>

                                                    <label className='lms_checkbox my-3'>
                                                        New Password
                                                    </label>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Enter your message here"
                                                            autocomplete="on"  onChange={(e) => setContent(e.target.value)} required/>
                                                    </div>

                                                    <button class="btn_1 full_width text-center" onClick={() => onChangePassword()} type="button">Submit</button>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <Footer />

            </section>

            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i class="ti-angle-up"></i>
                </a>
            </div>


        </>
    )
}


export default ChangePassword;