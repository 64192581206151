import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Footer from '../Footer';
import { useNavigate } from "react-router-dom";
import SidebarMenu from '../SidebarMenu';
import DashboardHeader from '.././DashboardHeader';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Web3 from 'web3';


const Liverate = (props) => {
    document.title = `Liverate - ${process.env.REACT_APP_APP_NAME}`
    const [Content, setContent] = useState('');
    const [Status, setStatus] = useState(props.Setting.live_rate_status);
    // const [connectButton, setConnectButton] = useState(props.Setting.connect_button_status)

    const [isActive, setisActive] = useState(true);
    const [Setting, setSetting] = useState([]);
    const [Txs, setTxs] = useState('');
    const [Loading, setLoading] = useState(false);
    const [Total, setTotal] = useState(0);
    const [ratedata, setratedata] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const toggleClass = () => {
        setisActive(!isActive)
    };
    // const stachange = (status,dasd) => {
    //     setStatus(status)
    // }
    const onGetSetting = async (e) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getsetting", setting: 1 })
            .then(res => {
                console.log(res.data[0]);
                setSetting(res.data[0]);
            })
    }
    // const smartContract = async () => {

    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "userdataforsc" })
    //         .then(async res => {
    //             console.log("res", res.data);

    //             //array of user with address and balance
    //             var user = []
    //             user = res.data;
    //             // console.log("total : ", user)
    //             var userAddress = user.map((item) => { return item.address })
    //             var userBalance = user.map((item) => { return web3.utils.toWei(parseFloat(item.balance).toFixed(4), "ether") })
    //             // console.log("user", userAddress);
    //             // console.log("user balance  : ", userBalance)
    //             //-----------------//

    //             // "wdrtkn" is method 
    //             await contract.methods.updatebalance(userAddress, userBalance).send({ from: accounts[0] },
    //                 async function (err, transactionId) {
    //                     if (err) {
    //                         alert(err);
    //                         return false;
    //                     }
    //                     if (transactionId) {
    //                         await insertSmartContarctLog(transactionId)
    //                     }
    //                     setthash(transactionId)
    //                 });
    //         })
    // }
    // const onChangeWebsite = async (e) => {
    //     const web3 = new Web3(Web3.givenProvider || `${process.env.REACT_APP_WEB3_PROVIDER}`);

    //     const network = Number(process.env.REACT_APP_CHAINID);

    //     var provider = window.ethereum;
    //     await provider.request({
    //         method: "eth_requestAccounts",
    //     });
    //     const accounts = await web3.eth.getAccounts();
    //     const chainid = await web3.eth.getChainId();

    //     // console.log(accounts[0], chainid, "accountsaccounts");
    //     if (chainid !== network) {
    //         alert("Connect to bsc smart chain")
    //         return '0'
    //     }

    //     const contract = new web3.eth.Contract(JSON.parse(Setting.buy_sell_contract_abi), Setting.buy_sell_contract_address);
    //     if (accounts[0].toLowerCase() !== Setting.buy_sell_contract_owner.toLowerCase()) {
    //         alert("Only owner can change price");
    //         return '0';
    //     }
    //     await contract.methods.updatePrice(web3.utils.toWei(Content.toString(), "ether")).send({ from: accounts[0] },
    //         async function (err, transactionId) {
    //             if (err) {
    //                 alert(err.message);
    //                 return false;
    //             }
    //             if (transactionId) {
    //                 setTxs(transactionId);
    //                 await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "live_rate_change", status: Status, content: Content, hash: transactionId })
    //                     .then(res => {
    //                         alert("data Updated successfully");
    //                     })
    //             }
    //         });
    // }
    const onChangeWebsite = async (e) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "live_rate_change", status: Status, content: Content })
            .then(res => {
                alert("data Updated successfully");
            })
    }
    const fetchUsers = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "all_live_rate", page: page, per_page: perPage })
            .then(res => {
                console.log(res.data);
                setratedata(res.data.data);
                setTotalRows(res.data.total);

            })
        setLoading(false);
    };

    useEffect(() => {
        setStatus(props.Setting.live_rate_status);
        setContent(props.Setting.live_rate_content)
        onGetSetting()
        fetchUsers(1)
    }, [props])
    const handlePageChange = page => {

        setCurrentPage(page)

    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setPerPage(newPerPage);
        setLoading(false);
    };
    const columns = [
        {
            name: "#",
            selector: row => row["sr"],
            key: "sr",
            width: "100px"
        },
        // {
        //     name: "Hash",
        //     selector: row => row["hash"],
        //     cell: (data) => <><a href={`${process.env.REACT_APP_TXN_URL}${data.hash}`} target={"_blank"}>{data.hash}</a></>,
        //     key: "hash",
        //     width: "600px"
        // },
        {
            name: "Rate($)",
            selector: row => row["rate"],
            key: "rate",
            width: "100px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "190px"
        },
        {
            name: "Offline Status",
            selector: row => row["status"].toString(),
            key: "status",
            width: "190px"
        }
    ];
    const data = [
        ...ratedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };

    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />
            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div className="main_content_iner overly_inner ">
                    <div className="container-fluid p-0 ">
                        <div className="row">
                            <div className="col-12">
                            </div>
                        </div>
                        {/* content here */}
                        <div className="card">
                            <div className="white_box mb_30">
                                <div className="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="page_title_left">
                                        <h3 className="mb-0">Live rate</h3>
                                        {/* <!-- <p>Dashboard/Crypto currenct</p> --> */}
                                    </div>
                                </div>
                                <div className="row justify-content-center">

                                    <div className="col-lg-6">
                                        <div className="modal-content cs_modal">
                                            <div className="modal-header justify-content-center theme_bg_1">
                                                <h5 className="modal-title text_white">Live rate</h5>
                                            </div>
                                            <div className="modal-body">
                                                <form>
                                                    {/* <label className='lms_checkbox_1'>
                                                        Off/On
                                                    </label>
                                                    <div class="checkbox_wrap d-flex align-items-center">
                                                        <label class="lms_checkbox_1" for="course_1">
                                                            <input type="checkbox" id="course_1" defaultChecked={Status} onChange={(e) => stachange(e.target.checked,e.target.value)} />
                                                            <div class="slider-check round"></div>
                                                        </label>
                                                    </div> */}
                                                    <label className='lms_checkbox_1'>
                                                        <strong>  Off/On  </strong>
                                                    </label>
                                                    <div class="checkbox_wrap d-flex align-items-center">
                                                        <label class="lms_checkbox_1" for="course_4">
                                                            <input type="checkbox" id="course_4" onClick={() => { setStatus(!Status) }} defaultChecked={Status} />
                                                            <div class="slider-check round"></div>
                                                        </label>
                                                    </div>
                                                    <label className='lms_checkbox my-3'>
                                                        Rate $ (Offline)
                                                    </label>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Enter your message here"
                                                            autocomplete="on" defaultValue={props.Setting.live_rate_content} onChange={(e) => setContent(e.target.value)} />
                                                    </div>
                                                    <label className='lms_checkbox my-3'>
                                                        Rate $ (Online)
                                                    </label>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Enter your message here"
                                                            autocomplete="on" value={props.Setting.live_rate}  readOnly />
                                                    </div>
                                                    <div className='text-center'>
                                                        <a href={`${process.env.REACT_APP_TXN_URL}${Txs}`} target="_blank">{Txs}</a>
                                                    </div>
                                                    <button className="btn_1 full_width text-center" onClick={() => onChangeWebsite()} type="button">Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="white_card_body pt-0" style={{ marginTop: "50px" }}>

                                    <div className="QA_section">
                                        <div className="QA_table mb-0 transaction-table">

                                            <div className="table-responsive">
                                                <DataTableExtensions {...tableData}
                                                    print={false}
                                                    export={false}>
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        noHeader
                                                        progressPending={Loading}
                                                        defaultSortField="id"
                                                        // sortIcon={<SortIcon />}
                                                        defaultSortAsc={true}
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        pagination
                                                        highlightOnHover
                                                        paginationServer
                                                        dense
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
            <div id="back-top" style={{ display: "none" }}>
                <a title="Go to Top" href="#">
                    <i class="ti-angle-up"></i>
                </a>
            </div>
        </>
    )
}
export default Liverate;