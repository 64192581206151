import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import axios from 'axios';
import DashboardHeader from './DashboardHeader';
import { useNavigate } from "react-router-dom";
import SidebarMenu from './SidebarMenu';
const AddTransaction = (props) => {
    // console.log('yser',props.address);
    const [hash, sethash] = useState(null);
    const [address, setaddress] = useState(null);
    const [amt, setamt] = useState(0);
    const [tknrate, settknrate] = useState(0);
    const [tokens, settokens] = useState(0);
    const [datetime, setdatetime] = useState(null);
    const [end_datetime, setend_datetime] = useState(null);
    const [type, settype] = useState(0);
    const [error, seterrpr] = useState(null);
    const [isActive, setisActive] = useState(true);

    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }

    const toggleClass = () => {
        setisActive(!isActive)
    };
    const getUserData = async (account) => {
        if (!account) {
            return
        }
        await axios.post(process.env.REACT_APP_BACKEND_LINK, { method: "getuser", address: account.toLowerCase() })
            .then(res => {
                console.log("resd", res.data);
                if (!res.data.length) {
                    seterrpr("Address not found")
                    return
                }
                seterrpr("")
                if (res.data[0].status === 1) {
                    if(res.data[0].restaking){
                        settype(1)
                    }else{
                        seterrpr("Restake not activate yet")
                    }

                } else {
                    settype(0)
                }
            })
    };

    const addtr = async () => {
        console.log("type" + type);
        const hashRegex = /^(0x)?[0-9a-fA-F]{64}$/;
        if (!hashRegex.test(hash)) {
            seterrpr("Enter valid hash")
            return
        }
        const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        if (!addressRegex.test(address)) {
            seterrpr("Enter valid Address")
            return
        }
        if (!amt) {
            seterrpr("Enter valid Amount")
            return
        }
        if (!tknrate) {
            seterrpr("Enter valid Token Rate")
            return
        }
        if (!tokens) {
            seterrpr("Enter valid Token Rate")
            return
        }
        if (!datetime) {
            seterrpr("Enter valid Datetime")
            return
        }
        if (!end_datetime) {
            seterrpr("Enter valid End Datetime")
            return
        }
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            method: "addtransaction",
            hash: hash.toLowerCase(),
            fromaddress: address.toLowerCase(),
            toaddress: "0x1deEfB477F21025e05eBb4dE9261391E719d8831",
            total_investment: Number(amt),
            token_rate: Number(tknrate),
            total_token: Number(tokens),
            datetime: datetime,
            datetime_unix: 0,
            end_datetime: end_datetime,
            end_datetime_unix: 0,
            block_timestamp: "0",
            // for restake type 1
            // t_type: 1,
            t_type: type,
            status: 1,
            isAdmin: true
        })
            .then(res => {
                if (res.data.error) {
                    seterrpr(res.data.error)
                    return
                }
                seterrpr(res.data.data)
                console.log("fsdf", res);
            })
    }
    useEffect(() => {
        settokens(amt / tknrate)
    }, [amt, tknrate])
    useEffect(() => {
        getUserData(address)
    }, [address])
    return (
        <>

            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">
                        <div className="col-xl-12">
                            <div className="white_card card_height_100 mb_30">
                                <div className="white_card_header">
                                    <div className="box_header m-0">
                                        <div className="main-title">
                                        </div>
                                    </div>
                                </div>
                                <div className="white_card_body">
                                    <div className="QA_section">
                                        <div className="white_box_tittle list_header">
                                            <h4>Add Transaction</h4>
                                            <div className="box_right d-flex lms_block">
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div class="col-lg-6 form-group">
                                                <h5>Hash</h5>
                                                <input type="text" id="hash" class="form-control" placeholder="Enter hash#" autocomplete="on" onChange={(e) => sethash(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Address</h5>
                                                <input type="text" class="form-control" placeholder="Address" autocomplete="on" onChange={(e) => setaddress(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Amount $</h5>
                                                <input type="number" class="form-control" placeholder="Enter $" autocomplete="on" onChange={(e) => setamt(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Token Rate $</h5>
                                                <input type="number" class="form-control" placeholder="Token Rate" autocomplete="on" onChange={(e) => settknrate(e.target.value)} />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Tokens</h5>
                                                <input type="number" class="form-control" placeholder="Tokens" autocomplete="on" value={tokens}  />
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>Datetime</h5>
                                                <input type="text" class="form-control" placeholder="2023-07-22 06:03:32" autocomplete="on" onChange={(e) => setdatetime(e.target.value)} />
                                                <span>Format: 2023-07-22 06:03:32 </span>

                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <h5>End Datetime</h5>
                                                <input type="text" class="form-control" placeholder="2023-07-22 06:03:32" autocomplete="on" onChange={(e) => setend_datetime(e.target.value)} />
                                                <span>Format: 2023-07-22 06:03:32 </span>
                                            </div>
                                            <div class="col-lg-6 ">
                                                <div className='form-group d-flex justify-content-between w-50'>
                                                    <h5>Type</h5>
                                                    <div className=' justify-content-start'>
                                                        Stake <input type="radio" class="form-control h-50" name='rad' checked={type === 0}  />
                                                    </div>
                                                    <div className=' justify-content-start'>
                                                        Re-Stake <input type="radio" class="form-control h-50" name='rad' checked={type === 1}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 form-group">
                                                <div className='pt-2'>
                                                    <span className='text-danger'>{error}</span>
                                                    <br />
                                                    <button type='button' class="btn_6 full_width text-center" onClick={() => addtr()} >Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </section>
        </>
    );
};

export default AddTransaction;