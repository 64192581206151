import React, { useState, useEffect } from "react";
import SidebarMenu from "../SidebarMenu";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles.css';

import DataTable from "react-data-table-component";

import DataTableExtensions from "react-data-table-component-extensions";
import Footer from "../Footer";
import DashboardHeader from '.././DashboardHeader';

const Levelincome = (props) => {

    document.title = `Levelincome - ${process.env.REACT_APP_APP_NAME}`
    const [isActive, setisActive] = useState(true);

    const toggleClass = () => {
        setisActive(!isActive)
    };
    const [stakedata, setstakedata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stakeddata, setstakeddata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')

    }
    const fetchUsers = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "adminReportLevelIncome", page: page, per_page: perPage })
            .then(res => {
                console.log("ress", res.data);
                setstakedata(res.data.data);
                setTotalRows(res.data.total);
            })
        setLoading(false);
    };
    const handlePageChange = page => {
        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        // console.log("perpage page : ", perPage , page)
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchLevelInc", search: searchInput, page: page, per_page: perPage })
                .then(res => {
                    // console.log("res is : ", res.data);
                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }

    const stakeD = async () => {
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", fromaddress: props.address, submethod: "dashData" })
            .then(res => {

                setstakeddata(res.data);
                setLoading(false)
            })
    }
    // const stake = async () => {
    //     setLoading(true)
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "adminReportLevelIncome" })
    //         .then(res => {
    //             setstakedata(res.data);
    //             setLoading(false)
    //         })
    // }
    // useEffect(() => {
    //     stake();
    //     stakeD();
    // }, [props])
    useEffect(() => {
        // stake();
        stakeD();
        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])
    const columns = [
        {
            name: "#",
            selector: row => row["sr"],
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: row => row["status"],
            key: "status",
            width: "100px"
        },
        {
            name: "To",
            selector: row => row["ftoaddress"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.ftoaddress)} id={data.address}><i className='fa fa-copy' /></span>&nbsp;<span> {data.ftoaddress}</span></>,
            key: "fromaddress",
            width: "430px"
        },
        {
            name: "From",
            selector: row => row["ffromaddress"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.ffromaddress)} id={data.address}><i className='fa fa-copy' /></span>&nbsp;<span> {data.ffromaddress}</span></>,
            key: "fromaddress",
            width: "430px"
        },
        {
            name: "Staking($)",
            selector: row => row["staking"],
            key: "staking",
            width: "150px"
        },
        {
            name: "Reward($)",
            selector: row => row["income"],
            key: "income",
            width: "150px"
        },
        {
            name: "Level ",
            selector: row => row["income_level"],
            key: "income_level",
            width: "100px"
        },
        // {
        //   name: "Round",
        //   selector: row=>row["round"],
        //   key: "round",
        //   width:100
        // },
        {
            name: "Reward(%)",
            selector: row => row["income_per"],
            key: "income_per",
            width: "150px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "200px"
        },
        {
            name: "Flushed",
            selector: "flushed",
            cell: (data) => <strong className='text-danger text-uppercase' >{data.flushed}</strong>,
            key: "flushed",
            width: 100
        }

    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };

    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />
                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-12">
                                {/* <div class="main-title mb_30">
                                    <h3 class="m-0">Welcome Back, Maria Pascle</h3>
                                </div> */}
                            </div>

                            <div class="col-lg-12">
                                <div class="white_card mb_30">
                                    <div class="white_card mb_30">
                                        <div class="white_card_header">
                                            <div class="box_header m-0">
                                                <div class="main-title">
                                                    <h3 class="m-0">Total Level Income</h3>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="white_card_body">
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <h4 class="f_s_22 f_w_700 mb-0">${stakeddata.total_level_income}</h4>
                                                    <p class="f_s_11 f_w_400">Total Balance</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="white_card_body pt-0" style={{ marginTop: "50px" }}>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                        <div class="QA_section">
                                            <div class="QA_table mb-0 transaction-table">

                                                <div class="table-responsive">
                                                    <DataTableExtensions {...tableData}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            defaultSortField="id"
                                                            progressPending={loading}
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={totalRows}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            highlightOnHover
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </section>
        </>
    )
}

export default Levelincome;