import axios from 'axios';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DashboardHeader = (props) => {
    const history = useNavigate();

    const toggleClass = () => {
        props.toggleClass();
    };
    if (!sessionStorage.getItem("DZPAZrclEQ3fDHSc")) {
        history('/');
    }
    const logout = () => {
        // sessionStorage.removeItem("DZPAZrclEQ3fDHSc");
        sessionStorage.clear();
        history('/listallstaking');
    }
    const onBackupData = async (e) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "backupdata"})
            .then(res => {
               
            })
    }


    // const onClear = async (e) => {
    //     if (window.confirm("This will delete all data. Are you sure?")) {
    //         axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "deletedata", address: props.currentAccount })
    //             .then(res => {

    //             })
    //     }
    // }
    return (
        <>
            <div className="container-fluid no-gutters">
                <div className="row">
                    <div className="col-lg-12 p-0 ">
                        <div className="header_iner d-flex justify-content-between align-items-center">
                            <div className="sidebar_icon d-lg-none" onClick={toggleClass}>
                                <i className="ti-menu" />
                            </div>
                            <div className="line_icon open_miniSide d-none d-lg-block" onClick={toggleClass}>
                                <img src="assets/img/line_img.png" alt="" />
                            </div>
                            <div className="header_right d-flex justify-content-between align-items-center">
                                {/* <div className='author_name'>
                                    <h4 className="f_s_15 f_w_500 mb-0 px-2 text-danger" type='button' onClick={() => onClear()}>Clear Data</h4>
                                </div> */}
                                <div className="profile_info d-flex align-items-center">
                                    <div className="author_name">
                                        <h4 className="f_s_15 f_w_500 mb-0">Admin</h4>
                                    </div>
                                    <div className="profile_info_iner">

                                        <div className="profile_info_details">

                                            <Link to='/ChangePassword'>Change Password</Link>
                                            {/* <a type='button' onClick={onBackupData}>Backup Data </a> */}
                                            <a type='button' onClick={logout}>Log Out </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default DashboardHeader;