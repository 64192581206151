import React, { useState } from 'react';
import Footer from './Footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Login = (props) => {
    document.title = `Login - ${process.env.REACT_APP_APP_NAME}`
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [error, seterror] = useState('');
    const history = useNavigate();
    // console.log(username, password);
    const checkLogin = async (e) => {
        // console.log("im herer");
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "adminLogin",submethod:"check", username: username, password: password })
            .then(res => {
               
                if (res.data === 1) {
                      window.sessionStorage.setItem("DZPAZrclEQ3fDHSc", "ONqUlF");
                      seterror("");
                    history("/dashboard");             
                }else{
                    seterror("No Data Found");
                    //ONqUlA false
                    window.sessionStorage.setItem("DZPAZrclEQ3fDHSc", "ONqUlA");
                }
            })
    }
    return (
        <>
            <section style={{ backgroundColor: "#fff" }}>
                <div class="container-fluid">
                    <div class="white_box mb_30" style={{ height: "800px", width: "100%" }}>
                        <div class="row justify-content-center">
                            <div class="col-lg-5">
                                <h1 class="d-flex justify-content-center mt-5">Login Page</h1>
                                <div class="modal-content cs_modal" style={{ marginTop: "100px" }}>
                                    <div class="modal-header justify-content-center theme_bg_1">
                                        <h5 class="modal-title text_white">Log in</h5>
                                    </div>
                                    <div class="modal-body">
                                        <form>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Enter your email" autocomplete="on" onChange={(e) => setusername(e.target.value)} />
                                            </div>
                                            <div class="form-group">
                                                <input type="password" class="form-control" placeholder="Password" autocomplete="on" onChange={(e) => setpassword(e.target.value)} />
                                            </div>
                                            <span className='text-danger'>{error}</span>
                                            <button type='button' class="btn_1 full_width text-center" onClick={checkLogin}>Log in</button>
                                            {/* <p>Need an account? <a href="/register"> Sign Up</a></p>
                                            <div class="text-center">
                                                <a href="/forgotpass" class="pass_forget_btn">Forget Password?</a>
                                            </div> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default Login;