import React from 'react';
import Footer from './Footer';

function Register() {
    return (
        <>



            <section style={{ backgroundColor: "#fff" }}>
                <div class="container-fluid">
                    <div class="white_box mb_30" style={{ height: "800px", width: "100%" }}>
                        <div class="row justify-content-center">
                            <div class="col-lg-5">
                                <h1 class="d-flex justify-content-center mt-5">Register</h1>
                                <div class="modal-content cs_modal" style={{ marginTop: "100px" }}>
                                    <div class="modal-header justify-content-center theme_bg_1">
                                        <h5 class="modal-title text_white">Log in</h5>
                                    </div>
                                    <div class="modal-body">
                                        <form>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Full Name" />
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Enter your email" />
                                            </div>
                                            <div class="form-group">
                                                <input type="password" class="form-control" placeholder="Password" />
                                            </div>
                                            <div class="form-group cs_check_box">
                                                <input type="checkbox" id="check_box" class="common_checkbox" />
                                                <label for="check_box">
                                                    Keep me up to date
                                                </label>
                                            </div>
                                            <a href="#" class="btn_1 full_width text-center"> Sign Up</a>
                                            <p>Need an account? <a class="pass_forget_btn" href="/">Log in</a></p>
                                            <div class="text-center">
                                                <a href="/forgotpass" class="pass_forget_btn">Forget
                                                    Password?</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    );
}

export default Register;