import React, { useState, useEffect } from 'react';
import SidebarMenu from './SidebarMenu';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import DashboardHeader from './DashboardHeader';

const ListBuySale = (props) => {
    document.title = `Buy / Sell - ${process.env.REACT_APP_APP_NAME}`
    const [buysaledata, setbuysaledata] = useState([]);
    const [isActive, setisActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const history = useNavigate();

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }
    const fetchBuySaleData = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap", submethod: "get", page: page, per_page: perPage })
            .then(res => {
                // console.log("page is....", page);
                // console.log("perPage", perPage);
                setbuysaledata(res.data.data);
                setTotalRows(res.data.dataLength);
                // console.log("total", res.data.dataLength);

            })
        setLoading(false);
    };
    const handlePageChange = page => {
        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchBuySaleData(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        console.log("page is2....");
        console.log("perPage2");
        setLoading(true);

        setPerPage(newPerPage);
        setLoading(false);
    };
    const getSearchData = async (page) => {
        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap", submethod: "search", input: searchInput, page: page, per_page: perPage })
                .then(res => {
                    setbuysaledata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.dataLength);
                })
        } else {
            fetchBuySaleData(1);
        }
    }

    useEffect(() => {
        if (searchInput !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchBuySaleData(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])

    const columns = [
        {
            name: "#",
            selector: row => row["id"],
            key: "id",
            width: "50px"
        },
        {
            name: "Status",
            selector: row => row.status ? "🟢" : "🔴",
            key: "status",
            width: "100px"
        },
        {
            name: "Hash",
            selector: row => row["hash"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.hash)} ><i className='fa fa-copy' /></span>&nbsp;<a target="_blank" href={`${process.env.REACT_APP_TXN_URL}${data.hash}`} rel="noopener noreferrer">{data.hash.substr(0, 5) + "..." + data.hash.substr(-5, 5)}</a></>,
            key: "hash",
            width: "150px"
        },
        {
            name: "Address",
            selector: row => row["address"],
            cell: (data) => <><span onClick={(e) => props.copyaddress(data.address)} ><i className='fa fa-copy' /></span>&nbsp;<span> {data.address}</span></>,
            key: "address",
            width: "220px"
        },
        {
            name: "Amount",
            selector: row => row["amount"],
            cell: (data) => <>{data.amount_oxy.toFixed(4)} {process.env.REACT_APP_TICKER}</>,
            key: "amount",
            width: "120px"
        },
        {
            name: "Amount",
            selector: row => row["amount"],
            cell: (data) => <>{data.amount_busd.toFixed(4)} {process.env.REACT_APP_BUSDTICKER}</>,
            key: "amount",
            width: "150px"
        },
        {
            name: "Rate($)",
            selector: row => row["rate"],
            cell: (data) => <>$ {data.rate.toFixed(4)}</>,
            key: "rate",
            width: "120px"
        },
        {
            name: "Type",
            selector: row => row["type"],
            cell: (data) => <><span className={`${data.type === 0 ? 'text-success' : 'text-danger'}`}>{data.type === 0 ? 'Buy' : 'Sell'}</span></>,
            key: "type",
            width: "120px"
        },
        {
            name: "Datetime",
            selector: row => row["datetime"],
            key: "datetime",
            width: "200px"
        }

    ];
    const data = [
        ...buysaledata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };
    const toggleClass = () => {
        setisActive(!isActive)
    };

    return (
        <>

            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="white_card card_height_100 mb_30">
                                    <div class="white_card_header">
                                        <div class="white_box_tittle list_header mb-0">
                                            <h4>All Buy / Sale</h4>
                                            <div class="box_right d-flex lms_block">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="white_card_body">
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                        <div class="QA_section">
                                            <div class="QA_table mb_30">

                                                <DataTableExtensions {...tableData}>
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        noHeader
                                                        progressPending={loading}
                                                        defaultSortField="id"
                                                        defaultSortAsc={true}
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        pagination
                                                        highlightOnHover
                                                        paginationServer
                                                        dense
                                                    />
                                                </DataTableExtensions>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </section>



        </>
    )
}


export default ListBuySale;