import React, { useState, useEffect } from "react";
import SidebarMenu from "../SidebarMenu";

import '../styles.css';
import DataTable from "react-data-table-component";

import DataTableExtensions from "react-data-table-component-extensions";
import Footer from '../Footer'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import DashboardHeader from '.././DashboardHeader';

const FlushedIncome = (props) => {
    document.title = `Flushed Income - ${process.env.REACT_APP_APP_NAME}`
    const [isActive, setisActive] = useState(true);

    const toggleClass = () => {
        setisActive(!isActive)
    };

    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [stakedata, setstakedata] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    var loginstatus = props.checklogin();
    if (!loginstatus) {
        history('/listallstaking')
    }

    const fetchUsers = async page => {
        // console.log(page,perPage);
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "adminFlushedIncome", page: page, per_page: perPage })
            .then(res => {
                console.log("ress", res.data);
                setstakedata(res.data.data);
                setTotalRows(res.data.total);
                // console.log("sas", stakedata);
            })
        setLoading(false);
    };
    const handlePageChange = page => {
        setCurrentPage(page)
        if (searchInput !== '') {
            getSearchData(page);
        }
        else {
            fetchUsers(page);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        // await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "adminReportStakingInc", page: page, per_page: perPage })
        //     .then(res => {
        //         setstakedata(res.data.data);
        //         // console.log("sas", stakedata);
        //     })
        setPerPage(newPerPage);
        setLoading(false);
    };

    // useEffect(() => {
    //     if (searchInput !== '') {
    //         getSearchData();
    //     }
    // }, [searchInput])
    useEffect(() => {
        // stake();

        if (searchInput.trim() !== '') {
            getSearchData(currentPage);
        }
        else {
            fetchUsers(currentPage); // fetch page 1 of users
        }
    }, [props, perPage, currentPage])

    const columns = [
        {
            name: "#",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        },
        {
            name: "address",
            selector: "address",
            key: "address",
            width: "300px"
        },
        {
            name: "Type",
            selector: "description",
            key: "description",
            width: "480px"
        },
        {
            name: "amount($)",
            selector: "amount",
            key: "amount",
            width: "180px"
        },
        {
            name: "Member Detail",
            selector: row => row["restaking"],
            cell: (data) => <button className='btn btn-primary btn-sm' onClick={() => history('/userreport', { state: { address: data.faddress } })} id={data.faddress}>Details</button>,
            key: "restaking",
            width: 100
        }
        // {
        //     name: "Debit($)",
        //     selector: "debit",
        //     key: "debit",
        //     width: "180px"
        // },
        // {
        //     name: "Balance($)",
        //     selector: "balance",
        //     key: "balance",
        //      width: "150px"
        // } 

    ];
    const data = [
        ...stakedata
    ];
    const tableData = {
        columns,
        data,
        filterPlaceholder: "Search"
    };

    const getSearchData = async (page) => {
        // console.log("res is : ", searchInput.trim() === '');
        // new Date(searchInput);
        let x = Date.parse(searchInput)
        console.log("dasdd", x);

        if (searchInput.trim() !== '') {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "searchData", submethod: "searchStakeInc", search: searchInput, page: page, per_page: perPage })
                .then(res => {
                    setstakedata(res.data.data)
                    setLoading(false)
                    setTotalRows(res.data.total);
                })
        } else {
            fetchUsers(1);
        }
    }

    console.log("page data is : ", perPage, currentPage)
    return (
        <>
            <SidebarMenu toggleClass={toggleClass} isActive={isActive} />

            <section className={isActive ? "main_content dashboard_part large_header_bg" : "main_content dashboard_part large_header_bg full_main_content"}>
                <DashboardHeader toggleClass={toggleClass} />

                <div class="main_content_iner overly_inner ">
                    <div class="container-fluid p-0 ">

                        <div class="row">
                            <div class="col-12">
                                {/* <div class="main-title mb_30">
                                    <h3 class="m-0">Welcome Back, Maria Pascle</h3>
                                </div> */}
                            </div>
                            <div class="col-lg-12">
                                <div class="white_card mb_30 card_height_100">
                                    <div class="white_card_header pb-0">
                                        <div class="box_header m-0">
                                            <div class="main-title">
                                                <h3 class="m-0">Flushed Income</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="white_card_body pt-0" style={{ marginTop: "50px" }}>
                                        <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
                                        <button type="button" className="btn btn-primary" onClick={() => getSearchData(currentPage)} >Search</button>
                                        <div class="QA_section">
                                            <div class="QA_table mb-0 transaction-table">
                                                <div class="table-responsive">
                                                    <DataTableExtensions {...tableData}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            defaultSortField="id"
                                                            progressPending={loading}
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={totalRows}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            highlightOnHover
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>


        </>
    )

}

export default FlushedIncome;